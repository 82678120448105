import React from 'react';
import classNames from 'classnames';
import styles from '../styles.scss';

interface IProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  onClick?: () => void;
}

const Card = ({ children, style, className, onClick }: IProps) => {
  const newStyles = {
    ...style,
  };

  return (
    <div onClick={onClick} className={classNames(styles.card, className)} style={newStyles}>
      {children}
    </div>
  );
};

export default Card;
