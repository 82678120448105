import React from 'react';
import classNames from 'classnames';
import styles from '../styles.scss';

interface IProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}

const Thead: React.FC<IProps> = ({ children, style, className }: IProps) => {
  return (
    <thead className={classNames(styles.tableThead, className)} style={style}>
      {children}
    </thead>
  );
};

export default Thead;
