import { AxiosError, AxiosResponse } from 'axios';
import { DashboardType } from '~/models/IDashboard';
import { IRequest } from '~/models/IRequest';
import axios from '~/lib/axios-client';
import { Auth } from '~/lib/auth-lib';

export const addDashboard = async (
  reportId: string,
  type = 'DEFAULT',
  workspaceId: string,
  subType?: string,
): Promise<IRequest> => {
  const body = {
    id: reportId,
    type: type,
    company: workspaceId,
    subType,
  };

  return await axios
    .post(`/v1/workspace/dashboards/add`, body, {
      headers: {
        Authorization: `Bearer ${Auth.getToken()}`,
      },
    })
    .then((res: AxiosResponse) => {
      const data: IRequest = res.data;

      if (data.success) {
        return data;
      }
    })
    .catch((err: AxiosError) => {
      if (err.response) {
        return err.response.data;
      }
    });
};
