import React, { useEffect } from 'react';
import styles from '../styles.scss';
import classNames from 'classnames';

interface IProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  onClose?: () => void;
}

const Modal: React.FC<IProps> = ({ children, style, className, onClose }: IProps) => {
  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key == 'Escape') onClose();
  };

  const newStyles = {
    ...style,
  };

  return (
    <div role="modal" onClick={onClose} className={styles.modal}>
      <div
        onClick={event => event.stopPropagation()}
        className={classNames(styles.modalContainer, className)}
        style={newStyles}
      >
        {children}
      </div>
    </div>
  );
};

export default Modal;
