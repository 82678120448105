import { Action } from '../actions';
import { setLocalStorage, getLocalStorage } from '~/services/localstorage-service';
import { TOGGLE_COLLAPSE } from '../constants';

export const TOGGLE_LOCAL_STORAGE = 'navigation';

export interface INavigationState {
  isCollapsed: boolean;
}

const initialState = {
  isCollapsed: Boolean(getLocalStorage(TOGGLE_LOCAL_STORAGE)),
};

export const navigationReducer = (state: INavigationState = initialState, action: Action) => {
  switch (action.type) {
    case TOGGLE_COLLAPSE: {
      setLocalStorage(TOGGLE_LOCAL_STORAGE, action.payload);
      return { ...state, isCollapsed: action.payload };
    }
    default:
      return state;
  }
};
