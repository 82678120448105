import React, { useEffect } from 'react';
import { Auth } from '~/lib/auth-lib';
import { push } from 'connected-react-router';
import { Routes } from '~/routes/Routes';
import { connect } from 'react-redux';

interface IProps {
  push: typeof push;
}

const LogoutScreen = ({ push }: IProps) => {
  useEffect(() => {
    Auth.deauthenticateUser();
    push(Routes.BASE);
  }, []);

  return <div />;
};

export default connect(null, { push })(LogoutScreen);
