import React, { useState } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Input from '~/components/Input';
import Button from '~/components/Button';
import { Routes } from '~/routes/Routes';
import styles from '../styles.scss';
import AuthContent from '~/components/AuthContent';

const ForgotPasswordScreen = () => {
  const onSubmit = (event: React.FormEvent<HTMLInputElement>) => {
    event.preventDefault();
  };

  return (
    <AuthContent title="Forgot Password">
      <form>
        <Input type="email" placeholder="name@work-email.com" leftLabel="Email" name="email" />
        <Button onClick={onSubmit} className={styles.button}>
          Reset Password
        </Button>
      </form>
      <div className={classNames(styles.footer)}>
        <div className={classNames(styles.footerDivider)}>OR</div>
        <div className={classNames(styles.footerDetails)}>
          Remember your password again?{' '}
          <Link to={Routes.LOGIN}>
            <span>Log In</span>
          </Link>
        </div>
      </div>
    </AuthContent>
  );
};

export default ForgotPasswordScreen;
