import React, { useEffect, useState } from 'react';
import AutoComplete from '~/components/AutoComplete';
import Button from '~/components/Button';
import Input from '~/components/Input';
import { Modal, ModalBody, ModalFooter, ModalTitle } from '~/components/Modal';
import { ToastVariants, useToast } from '~/components/Toast';
import { IUser } from '~/models/IUser';
import { IWorkspace } from '~/models/IWorkspace';
import { getUsers } from '~/services/users-service';
import { addUser } from '~/services/workspaces-service';

interface IProps {
  onClose: () => void;
  workspace: IWorkspace;
}

const WorkspaceInvite: React.FC<IProps> = ({ onClose, workspace }: IProps) => {
  const [email, setEmail] = useState<string>();
  const [users, setUsers] = useState<Array<IUser>>([]);
  const [selected, setSelected] = useState<Array<string>>([]);
  const { addToast } = useToast();

  /**
   * @TODO make this dynamic.
   */
  useEffect(() => {
    getUsers(500, 0, '', '')
      .then(res => {
        if (res.success) {
          setUsers(res.users);
        }
      })
      .catch(err => {
        console.error(err);
      });
  }, []);

  const handleSubmit = async () => {
    console.log('#submit');

    if (selected.length <= 0) {
      return;
    }

    selected.forEach(async value => {
      await addUser(value, workspace._id)
        .then(res => {
          if (res.success) {
            addToast('Invited the user to the workspace.');

            return onClose && onClose();
          }

          addToast('Failed to invite the user.', ToastVariants.DANGER);
        })
        .catch(err => {
          console.error(err);
        });
    });
  };

  return (
    <Modal onClose={onClose}>
      <ModalTitle>Invite user into {workspace.company}&apos;s Workspace</ModalTitle>
      <ModalBody>
        <Input style={{ width: '100%' }} readOnly={true} placeholder={workspace.company} name="workspace" />
        <AutoComplete
          suggestions={users.map(user => user.email)}
          selectCounter={true}
          onSelected={({ selected }) => setSelected(selected)}
        />
      </ModalBody>
      <ModalFooter style={{ justifyContent: 'flex-end' }}>
        <Button style={{ marginRight: '15px' }} onClick={onClose}>
          Close
        </Button>
        <Button onClick={handleSubmit}>Save changes</Button>
      </ModalFooter>
    </Modal>
  );
};

export default WorkspaceInvite;
