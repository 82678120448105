import React from 'react';
import { Link } from 'react-router-dom';
import { Routes } from '~/routes/Routes';
import styles from '../styles.scss';

const NotFoundScreen = () => {
  return (
    <div className={styles.container}>
      <h1>404</h1>
      <Link to={Routes.BASE}>Return to Home</Link>
    </div>
  );
};

export default NotFoundScreen;
