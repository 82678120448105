import React from 'react';
import classNames from 'classnames';
import { LogoLight } from '../Icons';
import styles from './styles.scss';

interface IProps {
  title?: string;
  children: React.ReactNode;
}

const AuthContent: React.FC<IProps> = ({ title, children }: IProps) => {
  const renderTitle = () => {
    if (!title) {
      return null;
    }

    return <h1 className={styles.containerAuthCenter}>{title}</h1>;
  };

  return (
    <div className={styles.container}>
      <img src={LogoLight} className={styles.containerLogo} alt="Logo" />
      <div className={styles.containerAuth}>
        {renderTitle()}
        {children}
      </div>
      <div className={classNames(styles.containerFooter)}>
        <ul>
          <li>&copy; {new Date().getFullYear()} MeMo²</li>
          <li>Contact</li>
          <li>Privacy &amp; Terms</li>
        </ul>
      </div>
    </div>
  );
};

export default AuthContent;
