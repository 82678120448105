import { IRequestError } from '~/models/IRequest';
import { Action, IRegisterUser } from '../actions/register-actions';

import { REGISTER_REQUEST, REGISTER_SUCCESS, REGISTER_FAILURE } from '../constants';

export interface IRegisterState {
  isFailure: boolean;
  isLoading: boolean;
  user: IRegisterUser;
  errors: Array<IRequestError> | undefined;
}

const initialState: IRegisterState = {
  isFailure: false,
  isLoading: false,
  user: {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    passwordConfirm: '',
  },
  errors: undefined,
};

export const registerReducer = (state: IRegisterState = initialState, action: Action) => {
  switch (action.type) {
    case REGISTER_REQUEST: {
      return { ...state, isLoading: action.payload };
    }
    case REGISTER_SUCCESS: {
      return { ...state, user: action.payload, isLoading: false };
    }
    case REGISTER_FAILURE: {
      return { ...state, errors: action.payload, isLoading: false, isFailure: true };
    }
    default:
      return state;
  }
};
