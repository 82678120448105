import AppScreen from './containers/AppScreen';
import { WorkspaceScreen, DashboardsScreen } from './containers/DashboardsScreen';
import FileShareScreen from './containers/FileShareScreen';
import ReportsScreen from './containers/ReportsScreen';
import ToolsScreen from './containers/ToolsScreen';
import QuotaScreen from './containers/QuotaScreen';
import UserSettingsScreen from './containers/UserSettingsScreen';

export {
  WorkspaceScreen,
  AppScreen,
  DashboardsScreen,
  FileShareScreen,
  UserSettingsScreen,
  ToolsScreen,
  QuotaScreen,
  ReportsScreen,
};

export default {
  AppScreen,
  DashboardsScreen,
  WorkspaceScreen,
  FileShareScreen,
  UserSettingsScreen,
  ReportsScreen,
  ToolsScreen,
  QuotaScreen,
};
