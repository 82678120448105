import Card from './Card';
import CardHeader from './CardHeader';
import CardFooter from './CardFooter';
import CardBody from './CardBody';

export { Card, CardHeader, CardBody, CardFooter };

export default {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
};
