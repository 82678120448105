export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm: number = decimals < 0 ? 0 : decimals;
  const sizes: string[] = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i: number = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const truncateLongName = (string: string, length: number, separator?: string): string => {
  if (string.length <= length) return string;

  separator = separator || '...';

  const separatorLength = separator.length,
    charsToShow = length - separatorLength,
    frontChars = Math.ceil(charsToShow / 2),
    backChars = Math.floor(charsToShow / 2);

  return string.substr(0, frontChars) + separator + string.substr(string.length - backChars);
};

export const downloadBlob = (blob: BlobPart, filename: string): void => {
  const downloadUrl = window.URL.createObjectURL(new Blob([blob]));

  const link = document.createElement('a');

  link.href = downloadUrl;

  link.setAttribute('download', filename);

  document.body.appendChild(link);

  link.click();

  link.remove();
};
