import React from 'react';
import AppContent from '~/components/AppContent';

import { Card, CardHeader, CardBody, CardFooter } from '~/components/Card';
import { Routes } from '~/routes/Routes';
import { history } from '~/store';

import styles from '../styles.scss';

const ToolsScreen: React.FC = () => {
  const cards = [{ name: 'quota', label: 'Quota', route: Routes.QUOTA }];

  return (
    <AppContent>
      <div className={styles.cards}>
        {cards.map(({ label, name, route }) => (
          <Card onClick={() => history.push(route)} key={name} className={styles.cardsCard}>
            <CardHeader>{label}</CardHeader>
            <CardBody>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
              et dolore magna.
            </CardBody>
            <CardFooter>MeMo²</CardFooter>
          </Card>
        ))}
      </div>
    </AppContent>
  );
};

export default ToolsScreen;
