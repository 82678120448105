import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import styles from './styles.scss';

const KEY_CODE = {
  backspace: 8,
  left: 37,
  up: 38,
  right: 39,
  down: 40,
};

export interface IProps {
  length?: number;
  placeholder?: string;
  onComplete: (code: string) => void;
  isComplete?: boolean;
  isError?: boolean;
}

const VerifyInputBar = ({ length, placeholder, onComplete, isComplete = false, isError = false }: IProps) => {
  const [code, setCode] = useState([...Array(length)].map(() => ''));
  const inputs = useRef([]);

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>, slot: number) => {
    const value = event.target.value;
    if (/[^0-9]/.test(value)) return;
    const newCode = [...code];
    newCode[slot] = value;
    setCode(newCode);
    if (slot !== length - 1) {
      inputs.current[slot + 1].focus();
    }
    if (newCode.every(num => num !== '')) {
      onComplete(newCode.join(''));
    }
  };

  const onKeyUp = (event: React.KeyboardEvent, slot: number) => {
    switch (event.keyCode) {
      case KEY_CODE.backspace:
        event.preventDefault();
        if (!code[slot] && slot !== 0) {
          const newCode = [...code];
          newCode[slot - 1] = '';
          setCode(newCode);
          inputs.current[slot - 1].focus();
        }
        break;
      case KEY_CODE.left:
        event.preventDefault();

        inputs.current[slot - 1].focus();
        break;
      case KEY_CODE.right:
        event.preventDefault();

        inputs.current[slot + 1].focus();
        break;
      case KEY_CODE.up:
      case KEY_CODE.down:
        event.preventDefault();
        break;
      default:
        break;
    }
  };

  const onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select();
  };

  const newClassName = classNames({
    [styles.success]: isComplete,
    [styles.error]: isError,
  });

  return (
    <div className={styles.verify}>
      <div className={styles.verifyCode}>
        {code.map((num, id) => {
          return (
            <input
              key={id}
              aria-label={`${id + 1} of ${length}`}
              className={newClassName}
              placeholder={placeholder}
              aria-disabled={isComplete}
              type="text"
              disabled={isComplete}
              autoFocus={!code[0].length && id === 0}
              inputMode="numeric"
              value={num}
              onChange={event => handleInput(event, id)}
              onKeyUp={event => onKeyUp(event, id)}
              maxLength={1}
              onFocus={onFocus}
              ref={ref => inputs.current.push(ref)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default VerifyInputBar;
