export const LOGIN_REQUEST = '@@auth/LOGIN_REQUEST';
export type LOGIN_REQUEST = typeof LOGIN_REQUEST;

export const LOGIN_SUCCESS = '@@auth/LOGIN_SUCCESS';
export type LOGIN_SUCCESS = typeof LOGIN_SUCCESS;

export const LOGIN_FAILURE = '@@auth/LOGIN_FAILURE';
export type LOGIN_FAILURE = typeof LOGIN_FAILURE;

export const VERIFY_REQUEST = '@@auth/VERIFY_REQUEST';
export type VERIFY_REQUEST = typeof VERIFY_REQUEST;

export const VERIFY_REQUEST_SUCCESS = '@@auth/VERIFY_REQUEST_SUCCESS';
export type VERIFY_REQUEST_SUCCESS = typeof VERIFY_REQUEST_SUCCESS;

export const VERIFY_REQUEST_FAILURE = '@@auth/VERIFY_REQUEST_FAILURE';
export type VERIFY_REQUEST_FAILURE = typeof VERIFY_REQUEST_FAILURE;

export const REGISTER_REQUEST = '@@auth/REGISTER_REQUEST';
export type REGISTER_REQUEST = typeof REGISTER_REQUEST;

export const REGISTER_SUCCESS = '@@auth/REGISTER_SUCCESS';
export type REGISTER_SUCCESS = typeof REGISTER_SUCCESS;

export const REGISTER_FAILURE = '@@auth/REGISTER_FAILURE';
export type REGISTER_FAILURE = typeof REGISTER_FAILURE;
