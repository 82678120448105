import React from 'react';
import styles from '../styles.scss';
import classNames from 'classnames';

interface IProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}

const ModalFooter: React.FC<IProps> = ({ children, style, className }: IProps) => {
  const newStyles = {
    ...style,
  };

  return (
    <div className={classNames(styles.modalContainerFooter, className)} style={newStyles}>
      {children}
    </div>
  );
};

export default ModalFooter;
