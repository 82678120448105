import Modal from './Modal';
import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';
import ModalTitle from './ModalTitle';

export { Modal, ModalBody, ModalFooter, ModalTitle };

export default {
  Modal,
  ModalBody,
  ModalFooter,
  ModalTitle,
};
