import React from 'react';
import classNames from 'classnames';
import VerifyInputBar from '~/components/VerifyInputBar';
import { connect } from 'react-redux';
import { IRootState } from '~/store';
import { verify } from '../actions/verify-actions';
import AuthContent from '~/components/AuthContent';
import styles from '../styles.scss';
import { useToast } from '~/components/Toast';

interface IStoreProps {
  email: string;
  verify: typeof verify;
  isFailure: boolean;
  isSuccess: boolean;
  isLoading: boolean;
}

type IProps = IStoreProps;

const VerifyScreen = ({ email, verify, isSuccess, isFailure }: IProps) => {
  const { addToast } = useToast();

  const onComplete = (token: string) => {
    addToast('Succesfully veried your account');
    return verify(email, parseInt(token));
  };

  return (
    <AuthContent title={'Check your email for a token'}>
      <p className={styles.tooltip}>
        We’ve sent a 6-digit code to <span>{email ? email : 'name@work-email.com'}</span>. The code expires shortly, so
        please enter it soon.
      </p>

      <VerifyInputBar
        onComplete={token => onComplete(token)}
        length={6}
        placeholder={'0'}
        isComplete={isSuccess}
        isError={isFailure}
      />

      <div className={classNames(styles.tooltipInfo)}>
        <p>Can’t find your code? Check your spam folder!</p>
      </div>
    </AuthContent>
  );
};

const mapStateToProps = ({ register, verify }: IRootState) => ({
  email: register.user.email,
  isFailure: verify.isFailure,
  isLoading: verify.isLoading,
  isSuccess: verify.isSuccess,
});

export default connect(mapStateToProps, { verify })(VerifyScreen);
