import { AxiosError, AxiosResponse } from 'axios';
import { Auth } from '~/lib/auth-lib';
import axios from '~/lib/axios-client';
import { IRequest } from '~/models/IRequest';

export const getWorkspaces = async (limit: number, page: number, key: string, value: string) => {
  // Set the key to empty if no value provided.
  if (value.trim().length <= 0) {
    key = '';
  }

  return await axios
    .get('/v1/workspaces', {
      params: {
        limit,
        page,
        key,
        value,
      },
      headers: {
        Authorization: `Bearer ${Auth.getToken()}`,
      },
    })
    .then((res: AxiosResponse) => {
      const data: IRequest = res.data;

      if (data.success) {
        return data;
      }
    })
    .catch((err: AxiosError) => {
      if (err.response) {
        return err.response.data;
      }
    });
};

export const getWorkspace = async (id: string) => {
  return await axios
    .get(`/v1/workspaces/${id}`, {
      headers: {
        Authorization: `Bearer ${Auth.getToken()}`,
      },
    })
    .then((res: AxiosResponse) => {
      const data: IRequest = res.data;

      if (data.success) {
        return data;
      }
    })
    .catch((err: AxiosError) => {
      if (err.response) {
        return err.response.data;
      }
    });
};

export const addUser = async (userEmail: string, workspaceId: string) => {
  const body = {
    userEmail,
    workspaceId,
  };

  return await axios
    .put('/v1/workspaces/users', body, {
      headers: {
        Authorization: `Bearer ${Auth.getToken()}`,
      },
    })
    .then((res: AxiosResponse) => {
      const data: IRequest = res.data;

      if (data.success) {
        return data;
      }
    })
    .catch((err: AxiosError) => {
      if (err.response) {
        return err.response.data;
      }
    });
};

export const changeColor = async (hex: string, workspaceId: string) => {
  const body = {
    hex,
    workspaceId,
  };

  return await axios
    .put('/v1/workspaces/settings/color', body, {
      headers: {
        Authorization: `Bearer ${Auth.getToken()}`,
      },
    })
    .then((res: AxiosResponse) => {
      const data: IRequest = res.data;

      if (data.success) {
        return data;
      }
    })
    .catch((err: AxiosError) => {
      if (err.response) {
        return err.response.data;
      }
    });
};

export const createWorkspace = async (userEmail: string, workspaceName: string) => {
  const body = {
    userEmail,
    workspaceName,
  };

  return await axios
    .post('/v1/workspaces/create', body, {
      headers: {
        Authorization: `Bearer ${Auth.getToken()}`,
      },
    })
    .then((res: AxiosResponse) => {
      const data: IRequest = res.data;

      if (data.success) {
        return data;
      }
    })
    .catch((err: AxiosError) => {
      if (err.response) {
        return err.response.data;
      }
    });
};

export const deleteWorkspace = async (id: string) => {
  return await axios
    .delete(`/v1/workspaces/${id}`, {
      headers: {
        Authorization: `Bearer ${Auth.getToken()}`,
      },
    })
    .then((res: AxiosResponse) => {
      const data: any = res.data;

      if (data.success) {
        return data;
      }

      return data;
    })
    .catch((err: AxiosError) => {
      if (err.response) {
        return err.response;
      }
    });
};

export const addUserToDashboard = async (workspaceId: string, dashboardId: string, userId: string) => {
  const body = {
    workspaceId,
    dashboardId,
    userId,
  };

  return await axios
    .put('/v1/workspaces/dashboards/users', body, {
      headers: {
        Authorization: `Bearer ${Auth.getToken()}`,
      },
    })
    .then((res: AxiosResponse) => {
      const data: IRequest = res.data;

      if (data.success) {
        return data;
      }
    })
    .catch((err: AxiosError) => {
      if (err.response) {
        return err.response.data;
      }
    });
};
