import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from './styles.scss';

/**
 * @TODO add types green, yellow, blue etc..
 */
interface IProps {
  suggestions: Array<string>;
  placeholder?: string;
  selectAll?: boolean;
  selectCounter?: boolean;
  onSelected?: ({ search, selected }: { search: string; selected: Array<string> }) => void;
}

const AutoComplete: React.FC<IProps> = ({
  suggestions,
  onSelected,
  placeholder = 'Type the value...',
  selectAll = true,
  selectCounter = false,
}: IProps) => {
  const [search, setSearch] = useState<string>('');
  const [displaySuggestions, setDisplaySuggestions] = useState<boolean>(false);
  const [selected, setSelected] = useState<Array<string>>([]);
  const [isSelectAll, setIsSelectAll] = useState<boolean>(true);
  const wrapperRef = useRef(null);

  useEffect(() => {
    onSelected && onSelected({ selected, search });
  }, [selected]);

  const handleSelected = useCallback(
    (value: string) => {
      if (selected.includes(value)) {
        setSelected(selected.filter(item => item !== value));
        return;
      }

      setSelected(values => [...values, value]);
    },
    [onSelected, selected],
  );

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      setDisplaySuggestions(false);
    }
  };

  const handleSelectAll = useCallback(() => {
    setIsSelectAll(!isSelectAll);
    if (!isSelectAll) {
      setSelected([]);
      return;
    }

    setSelected(suggestions.map(value => value));
  }, [onSelected, isSelectAll]);

  useEffect(() => {
    const handleClickOutside = () => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setDisplaySuggestions(false);
      }
    };

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  const renderSuggestions = () => {
    const suggest: Array<string> = suggestions.filter((value: string) => value.indexOf(search.toLowerCase()) > -1);

    if (suggest.length <= 0) {
      return <span className={styles.nomatch}>No matches found...</span>;
    } else {
      return suggest.map((value: string, idx: number) => {
        return (
          <label
            className={styles.autocompleteSuggestionsSuggest}
            key={`suggest-${value}-${idx}`}
            htmlFor={`suggest-${value}-${idx}`}
          >
            <input
              onClick={() => handleSelected(value)}
              onChange={() => null}
              checked={selected.includes(value) ? true : false}
              className={styles.autocompleteSuggestionsSuggestInput}
              type="checkbox"
              value={value}
              id={`suggest-${value}-${idx}`}
            />
            <span className={styles.autocompleteSuggestionsSuggestText}>{value}</span>
          </label>
        );
      });
    }
  };

  return (
    <div ref={wrapperRef} className={styles.autocomplete}>
      <div className={styles.autocompleteContainer} onClick={() => setDisplaySuggestions(true)}>
        {selectAll && (
          <label className={styles.autocompleteContainerLeft}>
            <input onClick={handleSelectAll} onChange={() => null} type="checkbox" />
          </label>
        )}
        <input
          className={styles.autocompleteContainerInput}
          type="text"
          value={search}
          placeholder={placeholder}
          onChange={event => setSearch(event.target.value)}
          onKeyDown={handleKeyDown}
        />
        {selectCounter && (
          <span className={styles.autocompleteContainerRight}>{selected.length <= 0 ? '0' : selected.length}</span>
        )}
      </div>
      {displaySuggestions && <div className={styles.autocompleteSuggestions}>{renderSuggestions()}</div>}
    </div>
  );
};

export default AutoComplete;
