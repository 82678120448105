const env = process.env.NODE_ENV || 'development';
const prefix = `DEEP:${env}::`;

export const setLocalStorage = <T = object>(key: string, value: T): void => {
  if (!localStorage) {
    return;
  }

  try {
    const serializedValue = JSON.stringify(value);
    localStorage.setItem(prefix + key, serializedValue);
  } catch (error) {
    throw new Error('Store serialization failed.');
  }
};

export const getLocalStorage = <T = object>(key: string): T | undefined => {
  if (!localStorage) {
    return;
  }

  try {
    const serializedValue = localStorage.getItem(prefix + key);
    if (serializedValue == null) {
      return;
    }
    return JSON.parse(serializedValue);
  } catch (error) {
    throw new Error('Store deserialization failed.');
  }
};

export const removeLocalStorage = <T = object>(key: string): T | void => {
  if (!localStorage) {
    return;
  }

  try {
    localStorage.removeItem(prefix + key);
  } catch (error) {
    throw new Error('Store deletion failed.');
  }
};
