import React, { useState } from 'react';
import TabTitle from '../TabTitle';
import styles from '../styles.scss';

interface IProps {
  children: React.ReactElement[];
}

const Tabs: React.FC<IProps> = ({ children }: IProps) => {
  const [selectedTab, setSelectedTab] = useState<number>(0);

  return (
    <div className={styles.tabs}>
      <div className={styles.tabsContainer}>
        {children.map((item, idx: number) => (
          <TabTitle
            key={`tab-title-${idx}`}
            active={selectedTab == idx}
            title={item.props.title}
            index={idx}
            setSelectedTab={setSelectedTab}
          />
        ))}
      </div>
      {children[selectedTab]}
    </div>
  );
};

export default Tabs;
