import React from 'react';
import { createPortal } from 'react-dom';
import styles from '../styles.scss';
import classNames from 'classnames';
import Toast, { IProps as IToast } from '../Toast';

interface IProps {
  toasts: IToast[];
}

const ToastContainer = ({ toasts }: IProps) => {
  return createPortal(
    <div className={classNames(styles.wrapper, styles.wrapperTopRight)}>
      {toasts.map((item: IToast) => (
        <Toast key={item.id} id={item.id} variant={item.variant} dismissTime={item.dismissTime}>
          {item.children}
        </Toast>
      ))}
    </div>,
    document.body,
  );
};

export default ToastContainer;
