import React from 'react';
import zxcvbn from 'zxcvbn';
import styles from './styles.scss';

interface IProps {
  password: string;
}

const PasswordStrengthBar = ({ password }: IProps) => {
  const scoreLevel = zxcvbn(password).score;
  const passwordLength = password.length;

  return (
    <>
      <p className={styles.policy}>
        Use your password manager or use a strong password which, contains at least 8 characters and no personal
        information.
      </p>
      <div className={styles.strength}>
        <div className={styles.strengthMeter} data-strength={passwordLength > 0 ? scoreLevel : null}></div>
      </div>
    </>
  );
};

export default PasswordStrengthBar;
