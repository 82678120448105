import { handleLogin } from '~/services/auth-service';
import { Dispatch } from 'redux';
import { history } from '~/store';

import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE } from '../constants';
import { Routes } from '~/routes/Routes';
import { IUser } from '../reducers/login-reducers';
import { IRequestError } from '~/models/IRequest';

export type Action = RequestAction | LoginAction | FailureAction;

type RequestAction = { type: LOGIN_REQUEST; payload: IUser };
type LoginAction = { type: LOGIN_SUCCESS; payload: IUser };
type FailureAction = {
  type: LOGIN_FAILURE;
  payload: Array<IRequestError>;
};

export const login = (email: string, password: string) => {
  return (dispatch: Dispatch) => {
    const request = (user: IUser): RequestAction => ({
      type: LOGIN_REQUEST,
      payload: user,
    });

    const success = (user: IUser): LoginAction => ({
      type: LOGIN_SUCCESS,
      payload: user,
    });

    const failure = (isFailure: Array<IRequestError>): FailureAction => ({
      type: LOGIN_FAILURE,
      payload: isFailure,
    });

    dispatch(request({ email: email }));

    handleLogin(email, password).then(response => {
      if (response.success) {
        dispatch(success(response));
        history.push(Routes.APP);
      } else {
        dispatch(failure(response.errors));
      }
    });
  };
};
