import React from 'react';
import classNames from 'classnames';
import styles from '../styles.scss';

interface IProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  onClick?: () => void;
  bold?: boolean;
}

const Td: React.FC<IProps> = ({ children, style, className, onClick, bold = false }: IProps) => {
  return (
    <td
      onClick={onClick}
      className={classNames(styles.tableTd, className, { [styles.tableTdBold]: bold })}
      style={style}
    >
      {children}
    </td>
  );
};

export default Td;
