import Table from './Table';
import Tbody from './Tbody';
import Td from './Td';
import Th from './Th';
import Thead from './Thead';
import Tr from './Tr';

export { Table, Tbody, Td, Th, Thead, Tr };

export default {
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
};
