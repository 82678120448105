import React, { useEffect, useState } from 'react';
import AppContent from '~/components/AppContent';

import styles from '../styles.scss';

import { getQuotasService, saveQuotaService, updateQuotaService, deleteQuotaService } from '~/services/quota-service';
import { IQuota, IQuotaFilter, IQuotaRequest } from '~/models/IQuota';
import Button from '~/components/Button';
import Input from '~/components/Input';
import IFrame from '~/components/IFrame';
import { Row, RowColumn } from '~/components/Row';
import Checkbox from '~/components/Checkbox';
import Select from '~/components/Select';
import QuotaModal from '../components/QuotaModal';
import Pagination from '~/components/Pagination';
import Datepicker from '~/components/Datepicker';

let onlineStyleSheet = '&_css=https://storage.memo2.space/dev/panel-tool/paneltool_quota-table_styles.css';

export const getIFrameHeight = (rowN: number): string => {
  return 29 + 23 * rowN + 'px';
};

export const intervalOptions = ['Weekly', 'Monthly', 'Quarterly', 'Ad-hoc'];

const defaultLimit = 15;

const QuotaScreen: React.FC = () => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [data, setData] = useState<Array<IQuota>>([]);
  const [selectedQuota, setSelectedQuota] = useState<Array<string>>([]);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [editing, setEditing] = useState<boolean>(false);
  const [quotaToEdit, setQuotaToEdit] = useState<IQuota>(null);

  const [searchCode, setSearchCode] = useState<string>('');
  const [searchName, setSearchName] = useState<string>('');
  const [deadlineStart, setDeadlineStart] = useState<Date>(null);
  const [deadlineEnd, setDeadlineEnd] = useState<Date>(null);
  const [intervalFilter, setIntervalFilter] = useState<Array<string>>([]);

  const [orderBy, setOrderBy] = useState<string>('');
  const [orderByDirection, setOrderByDirection] = useState<'asc' | 'desc'>('desc');

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageLimit, setPageLimit] = useState<number>(defaultLimit);
  const [totalPages, setTotalPages] = useState<number>(1);

  useEffect(() => {
    loadQuotas();
  }, [pageLimit, currentPage, searchCode, searchName, intervalFilter, deadlineStart, deadlineEnd]);

  const loadQuotas = async () => {
    setLoading(true);
    const filters = getFilters();
    const data: IQuotaRequest = await getQuotasService(currentPage, pageLimit, filters, getOrderBy());
    data.quotas.forEach(quota => {
      quota.deadline = new Date(quota.deadline);
    });
    setData(data.quotas);
    setSelectedQuota([]);
    setTotalPages(data.pages.totalPages);
    setLoading(false);
  };

  const getFilters = (): IQuotaFilter => {
    return { code: searchCode, name: searchName, interval: intervalFilter, deadlineStart, deadlineEnd };
  };

  const getOrderBy = () => {
    return { [orderBy]: orderByDirection };
  };

  const createQuota = async (quota: IQuota) => {
    setLoading(true);
    const res = await saveQuotaService(quota);
    console.log(res);
  };
  const editQuota = async (quota: IQuota) => {
    setLoading(true);
    const res = await updateQuotaService(quota);
    console.log(res);
  };
  const deleteQuota = async (code: string) => {
    setLoading(true);
    const res = await deleteQuotaService(code);
    console.log(res);
  };

  const getQuotaByCode = (code: string) => {
    for (let i = 0; i < data.length; i++) {
      if (data[i].code == code) {
        return data[i];
      }
    }
  };

  const selectAllOrNone = () => {
    if (selectedQuota.length == data.length) {
      setSelectedQuota([]);
      return;
    }
    const newSelection = [...data].map(quota => quota.code);
    setSelectedQuota(newSelection);
  };

  const renderModal = () => {
    return editing ? (
      <QuotaModal quotaToEdit={quotaToEdit} onSave={handleEdit} onCancel={() => setShowModal(!showModal)} />
    ) : (
      <QuotaModal onSave={handleCreate} onCancel={() => setShowModal(!showModal)} />
    );
  };

  const handleCreate = async (quota: IQuota, createNext: boolean) => {
    console.log('handlecreate');
    console.log(quota);
    setShowModal(false);
    await createQuota(quota);
    loadQuotas();
    createNext && handleOpenCreate();
  };
  const handleEdit = async (quota: IQuota) => {
    console.log('handleedit');
    console.log(quota);
    await editQuota(quota);
    loadQuotas();
    setEditing(false);
    setQuotaToEdit(null);
    setShowModal(false);
  };

  const renderActions = () => {
    const isMobile = window.innerWidth <= 768;
    return (
      <>
        {isMobile ? (
          'No actions for you'
        ) : (
          <>
            <Button type={'green'} onClick={handleOpenCreate}>
              Create
            </Button>
            {selectedQuota.length == 1 && (
              <Button type={'orange'} onClick={handleOpenEdit}>
                Edit
              </Button>
            )}
            {selectedQuota.length >= 1 && (
              <Button type={'red'} onClick={handleDelete}>
                Delete
              </Button>
            )}
          </>
        )}
      </>
    );
  };

  const handleOpenCreate = () => {
    if (editing) setEditing(false);
    setShowModal(true);
  };
  const handleOpenEdit = () => {
    setQuotaToEdit(getQuotaByCode(selectedQuota[0]));
    setEditing(true);
    setShowModal(true);
  };

  const handleDelete = async () => {
    const toDelete = selectedQuota;
    if (toDelete.length == 0) return;
    for (let i = 0; i < toDelete.length; i++) {
      await deleteQuota(toDelete[i]);
    }
    loadQuotas();
  };

  const renderFilters = () => {
    return (
      <>
        <Input
          placeholder="Search code"
          name={'searchCode'}
          onChange={({ value }) => {
            handleSearchCode(value);
          }}
          value={searchCode}
        />
        <Input
          placeholder="Search name"
          name={'searchName'}
          onChange={({ value }) => {
            handleSearchName(value);
          }}
          value={searchName}
          maxLength={255}
        />
        <Select
          options={intervalOptions}
          isMulti={true}
          name={'Frequency'}
          onChange={({ multiSelected }) => handleIntervalFilter(multiSelected)}
        />
        <Datepicker
          labelTop={'Deadline'}
          onPick={({ startDate, endDate }) => {
            console.log('onpick');
            setDeadlineStart(startDate);
            setDeadlineEnd(endDate);
          }}
          isRange
        />
      </>
    );
  };

  const handleSearchCode = (value: string) => {
    setSearchCode(value);
  };
  const handleSearchName = (value: string) => {
    setSearchName(value);
  };

  const handleIntervalFilter = (values: Array<string>) => {
    const newValues = values.map(value => {
      if (value == 'Ad-hoc') return 'adHoc';
      return value.toLowerCase();
    });
    setIntervalFilter(newValues);
  };

  const renderExtraActions = () => {
    return (
      <>
        <Checkbox
          checked={selectedQuota.length == data.length && data.length != 0}
          onClick={() => {
            selectAllOrNone();
          }}
          rightLabel={'Select all'}
          size={'small'}
        />
        <Select options={['deadline']} onChange={({ selected }) => setOrderBy(selected)} />
        <Select
          options={['asc', 'desc']}
          onChange={({ selected }) => {
            if (selected == 'asc' || selected == 'desc') setOrderByDirection(selected);
          }}
        />
      </>
    );
  };

  const renderQuotas = () => {
    const quotas = data.map(({ url, code, type, client, name, deadline, rowsInTable }: IQuota, idx: number) => {
      return (
        <div className={styles.quota} key={code}>
          <Row>
            <div className={styles.quotaCheckbox}>
              <Checkbox
                checked={selectedQuota.includes(code)}
                onClick={() => {
                  toggleSelect(code);
                }}
              />
            </div>
            <div className={styles.quotaInfo}>
              <p>
                {code} - {type ? type + ' - ' : ''} {name}
              </p>
              <p>Deadline: {deadline.toLocaleDateString('nl-NL')}</p>
            </div>
          </Row>
          <Row>
            <IFrame src={url} style={{ height: getIFrameHeight(rowsInTable) }} />
          </Row>
        </div>
      );
    });
    if (quotas.length == 0) return <p style={{ padding: '0.4rem 0' }}>No results</p>;
    return quotas;
  };

  const toggleSelect = (code: string) => {
    if (selectedQuota.includes(code)) {
      const newSelection = [...selectedQuota].filter(key => key != code);
      setSelectedQuota(newSelection);
      return;
    }
    const newSelection = [...selectedQuota, code];
    setSelectedQuota(newSelection);
  };

  const renderPagination = () => {
    return (
      <div className={styles.pagination}>
        <div className={styles.paginationLimit}>
          <p className={styles.paginationLimitText}>Limit per page</p>
          <Input
            name={'limit'}
            placeholder={''}
            onChange={({ value }) => handleLimit(value)}
            value={pageLimit.toString()}
            inputClassName={styles.paginationLimitInput}
          />
        </div>
        <Pagination
          onChangePage={({ newPage }) => handleNextPage(newPage)}
          currentPage={currentPage}
          perPage={pageLimit}
          totalPages={totalPages}
          style={{ margin: 0 }}
        />
      </div>
    );
  };

  const handleLimit = (value: string) => {
    const intval = parseInt(value);
    if (value.trim().length == 0 || isNaN(intval)) {
      setPageLimit(0);
      return;
    }
    if (intval > 999) return;
    setPageLimit(parseInt(value));
    setCurrentPage(0);
  };

  const handleNextPage = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <AppContent center={false} containerClass={styles.quotasMainContainer}>
      {showModal && renderModal()}

      <div className={styles.quotas}>
        <div className={styles.quotasHeader}>
          <h2 className={styles.quotasTitle}>Quota page</h2>
          <div className={styles.quotasActions}>{renderActions()}</div>
          <div className={styles.quotasActions}>{renderFilters()}</div>
          <div className={styles.quotasActions}>{renderExtraActions()}</div>
        </div>
        <div className={styles.quotasContent}>
          <div className={styles.quotasContentList}>
            {renderPagination()}
            {!isLoading ? renderQuotas() : 'Fetching data...'}
          </div>
        </div>
      </div>
    </AppContent>
  );
};

export default QuotaScreen;
