import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import styles from './styles.scss';

interface IProps {
  options: Array<string>;
  onChange?: ({ selected, multiSelected }: { selected: string; multiSelected: Array<string> }) => void;
  className?: string;
  style?: React.CSSProperties;
  type?: 'white' | 'gray' | 'yellow' | 'orange' | 'red' | 'green' | 'blue';
  name?: string;
  isMulti?: boolean;
}

const Select: React.FC<IProps> = ({
  options,
  onChange,
  type = 'green',
  style,
  className,
  name,
  isMulti = false,
}: IProps) => {
  const [selected, setSelected] = useState<string>(options[0]);
  const [multiSelected, setMultiSelected] = useState<Array<string>>([]);
  const [display, setDisplay] = useState<boolean>(false);
  const selectRef = useRef(null);

  useEffect(() => {
    onChange && onChange({ selected, multiSelected });
  }, [selected, multiSelected]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setDisplay(false);
      }
    };

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectRef]);

  const selectClasses = classNames(styles.selectContainer, className, {
    [styles[type]]: type,
  });

  const newStyles = {
    ...style,
  };

  const handleSelect = (option: string) => {
    if (!isMulti) {
      setSelected(option);
      return;
    }
    if (multiSelected.includes(option)) {
      const newSelection = [...multiSelected];
      newSelection.splice(newSelection.indexOf(option), 1);
      setMultiSelected(newSelection);
      return;
    }
    const newSelection = [...multiSelected, option];
    setMultiSelected(newSelection);
  };

  const getClassname = (isActive: boolean) => {
    return classNames(
      styles.selectContainerOptionsOption,
      { [styles.selectContainerOptionsOptionActive]: isActive },
      { [styles.optionHover]: !isMulti },
    );
  };

  return (
    <div ref={selectRef} className={styles.select} style={newStyles}>
      <div className={selectClasses} onClick={() => setDisplay(!display)}>
        <label className={styles.selectContainerInput}>{isMulti ? (name ? name : 'Select') : selected}</label>
        {display ? (
          <span className={styles.selectContainerArrowup} />
        ) : (
          <span className={styles.selectContainerArrowdown} />
        )}
      </div>
      {display && (
        <div className={styles.selectContainerOptions}>
          {options.map((option: string, idx: number) => {
            return (
              <div
                onClick={() => handleSelect(option)}
                className={getClassname(multiSelected.includes(option))}
                key={`select-${option}-${idx}`}
              >
                <div className={styles.selectContainerOptionsOptionText}>{option}</div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Select;
