import React, { useState } from 'react';

import styles from './styles.scss';

import { IQuota } from '~/models/IQuota';
import Button from '~/components/Button';
import Input from '~/components/Input';
import IFrame from '~/components/IFrame';
import { Row, RowColumn } from '~/components/Row';
import Checkbox from '~/components/Checkbox';
import { Modal, ModalBody, ModalFooter, ModalTitle } from '~/components/Modal';
import Select from '~/components/Select';
import { getIFrameHeight } from '../../containers/QuotaScreen';
import Calendar from '~/components/Calendar';
import { intervalOptions } from '../../containers/QuotaScreen';
import Datepicker from '~/components/Datepicker';

interface IProps {
  quotaToEdit?: IQuota;
  onSave: (values: IQuota, createNext: boolean) => void;
  onCancel: () => void;
}

const QuotaModal: React.FC<IProps> = ({ quotaToEdit, onSave, onCancel }) => {
  const edit = quotaToEdit != null;

  const [url, setUrl] = useState<string>(edit ? quotaToEdit.url : '');
  const [code, setCode] = useState<string>(edit ? quotaToEdit.code : '');
  const [name, setName] = useState<string>(edit ? quotaToEdit.name : '');
  const [interval, setInterval] = useState<string>(edit ? quotaToEdit.interval : intervalOptions[0]);
  const [rowsInTable, setRowsInTable] = useState<number>(edit ? quotaToEdit.rowsInTable : 10);
  const [deadline, setDeadline] = useState<Date>(edit ? quotaToEdit.deadline : new Date());
  const [showCalendar, setShowCalendar] = useState<boolean>(false);

  const [previewUrl, setPreviewUrl] = useState<string>(edit ? quotaToEdit.url : '');

  const handleSave = (createNext: boolean = false) => {
    const quota: IQuota = {
      url,
      code,
      name,
      interval,
      rowsInTable,
      deadline,
      createdBy: 'currentUser',
    };
    onSave(quota, createNext);
  };

  const handleSetRowsInTable = (value: string) => {
    if (value.trim().length == 0 || isNaN(parseInt(value))) {
      setRowsInTable(0);
      return;
    }
    setRowsInTable(parseInt(value));
  };

  const handleClear = () => {
    setUrl('');
    !edit && setCode('');
    setName('');
    setInterval(intervalOptions[0]);
    setRowsInTable(10);
    setDeadline(new Date());
    setPreviewUrl('');
  };
  const handleSetCode = (code: string) => {
    if (edit) return;
    setCode(code);
  };
  const handleSetInterval = (value: string) => {
    if (!intervalOptions.includes(value)) return;
    if (value == 'Ad-hoc') value = 'adHoc';
    else value = value.toLowerCase();
    setInterval(value);
  };
  const handleSetDeadline = (date: Date) => {
    console.log('handle deadline');
    setDeadline(date);
  };

  return (
    <Modal onClose={onCancel} className={styles.modal}>
      <ModalTitle>
        <h2>{edit ? 'Edit' : 'Create'}</h2>
      </ModalTitle>
      <ModalBody>
        <Row style={{ flexWrap: 'nowrap' }}>
          <RowColumn className={styles.modalLeft}>
            <Input
              name={'url'}
              onChange={({ value }) => {
                setUrl(value);
              }}
              placeholder={''}
              leftLabel={'Url'}
              value={url}
              inputClassName={styles.input}
              maxLength={255}
            />
            <Button style={{ marginLeft: 'auto' }} onClick={() => setPreviewUrl(url)}>
              Load preview
            </Button>
            <Input
              name={'code'}
              onChange={({ value }) => {
                handleSetCode(value);
              }}
              placeholder={''}
              leftLabel={'Projectcode'}
              readOnly={edit}
              value={code}
              inputClassName={styles.input}
            />
            <Input
              name={'name'}
              onChange={({ value }) => {
                setName(value);
              }}
              placeholder={''}
              leftLabel={'Projectname'}
              value={name}
              inputClassName={styles.input}
              maxLength={255}
            />
            <p style={{ lineHeight: '33.8px' }}>Frequency</p>
            <Select
              options={intervalOptions}
              onChange={({ selected }) => handleSetInterval(selected)}
              className={styles.select}
            />
            <div className={styles.deadline}>
              <Datepicker
                inputClassName={styles.deadlineInput}
                onPick={({ startDate }) => handleSetDeadline(startDate)}
              />
            </div>
            <Input
              name={'rowsInTable'}
              onChange={({ value }) => {
                handleSetRowsInTable(value);
              }}
              placeholder={''}
              leftLabel={'Amount of rows'}
              value={String(rowsInTable)}
              inputClassName={styles.input}
            />
          </RowColumn>
          <RowColumn className={styles.modalRight}>
            <p style={{ lineHeight: '33.8px' }}>Preview</p>
            <div className={styles.preview}>
              {previewUrl && (
                <IFrame src={previewUrl} style={{ height: getIFrameHeight(rowsInTable), minHeight: '100px' }} />
              )}
            </div>
          </RowColumn>
        </Row>
      </ModalBody>
      <ModalFooter className={styles.modalFooter}>
        <Button onClick={onCancel} type={'red'}>
          Cancel
        </Button>
        <Button onClick={e => handleSave()} type={'green'}>
          Save
        </Button>
        {!edit && (
          <Button onClick={e => handleSave(true)} type={'green'}>
            Save and create another
          </Button>
        )}
        <Button onClick={handleClear} type={'orange'}>
          Clear to default
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default QuotaModal;
