import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AppContent from '~/components/AppContent';
import { IWorkspace } from '~/models/IWorkspace';
import styles from '../styles.scss';
import { Heading, HeadingBody, HeadingTitle } from '~/components/Heading';
import { changeColor, getWorkspace, deleteWorkspace } from '~/services/workspaces-service';
import Button from '~/components/Button';
import LogoMiniColored from '~/assets/logo_mini_colored.png';
import CreateDashboard from '../components/CreateDashboard';
import { Table, Tbody, Td, Th, Thead, Tr } from '~/components/Table';
import { convertDate } from '~/utils/date';
import { IUser } from '~/models/IUser';
import { getUser } from '~/services/users-service';
import WorkspaceInvite from '../components/WorkspaceInvite';
import { IDashboard } from '~/models/IDashboard';
import { Tab, Tabs } from '~/components/Tabs';
import DashboardAddUser from '../components/DashboardAddUser';
import { Modal, ModalBody, ModalFooter, ModalTitle } from '~/components/Modal';
import ColorPicker from '~/components/ColorPicker';
import Progressbar from '~/components/Progressbar';
import { Row, RowColumn } from '~/components/Row';
import { history } from '~/store';
import { Routes } from '~/routes/Routes';

const userColumns = [
  { label: 'First Name', column: 'firstName' },
  { label: 'Last Name', column: 'lastName' },
  { label: 'Email', column: 'email' },
];

const dashboardColumns = [
  { label: 'Type', column: 'type' },
  { label: 'Sub Type', column: 'subType' },
  { label: 'Users', column: 'allowedUsers' },
  { label: 'Created', column: 'createdAt' },
];

const AdminWorkspaceScreen: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [workspace, setWorkspace] = useState<IWorkspace | undefined>(undefined);
  const [selectedDashboard, setSelectedDashboard] = useState<IDashboard | undefined>(undefined);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [users, setUsers] = useState<Array<IUser>>([]);
  const [error, setError] = useState<boolean>(false);
  const [owner, setOwner] = useState<IUser>();
  const [workspaceInviteUser, setWorkspaceInviteUser] = useState<boolean>(false);
  const [createDashboard, setCreateDashboard] = useState<boolean>(false);
  const [dashboardAddUser, setDashboardAddUser] = useState<boolean>(false);
  const [deleteWorkspaceModal, setDeleteWorkspaceModal] = useState<boolean>(false);
  const [colorWorkspaceModal, setColorWorkspaceModal] = useState<boolean>(false);
  const [hex, setHex] = useState<string>();

  useEffect(() => {
    getWorkspace(id)
      .then(res => {
        if (res.success) {
          setLoading(false);
          setWorkspace(res.workspace);
        }
      })
      .catch(err => {
        setError(true);
        setLoading(false);
        console.error(err);
      });
  }, [createDashboard, workspaceInviteUser, colorWorkspaceModal]);

  useEffect(() => {
    if (workspace) {
      setUsers([]);
      workspace.users.forEach(user => {
        getUser(user.userId).then(res => {
          if (res.user._id === workspace.owner) {
            setOwner(res.user);
          }
          setUsers(oldUsers => [...oldUsers, res.user]);
        });
      });
    }
  }, [workspace]);

  const handleColorSubmit = async (): Promise<void> => {
    return await changeColor(hex, workspace._id)
      .then(res => {
        if (res.success) {
          setColorWorkspaceModal(false);
          return;
        }
      })
      .catch(err => {
        console.error(err);
      });
  };

  const handleDeleteSubmit = async (): Promise<void> => {
    setDeleteWorkspaceModal(false);

    return await deleteWorkspace(workspace._id)
      .then(res => {
        if (res.success) {
          history.push(Routes.ADMIN_WORKSPACES);
          return;
        }
      })
      .catch(err => {
        console.error(err);
      });
  };

  return (
    <AppContent center={false}>
      {colorWorkspaceModal && (
        <Modal>
          <ModalTitle>Change Workspace Color</ModalTitle>
          <ModalBody>
            You&apos;re about to <span style={{ fontWeight: 500, fontStyle: 'italic' }}>CHANGE</span> the workspace
            color. Please be aware, that this action will be instantly dispatched across the workspace.
            <ColorPicker currentColor={workspace && workspace.color} onChange={({ hex }) => setHex(hex)} />
          </ModalBody>
          <ModalFooter>
            <Button style={{ marginRight: '15px' }} onClick={() => setColorWorkspaceModal(!colorWorkspaceModal)}>
              Close
            </Button>
            <Button onClick={handleColorSubmit}>Save changes</Button>
          </ModalFooter>
        </Modal>
      )}
      {deleteWorkspaceModal && (
        <Modal>
          <ModalTitle>Delete Workspace</ModalTitle>
          <ModalBody>
            You&apos;re about to <span style={{ fontWeight: 500, fontStyle: 'italic' }}>DELETE</span> the workspace.
            Please be aware, that this action can not be reverted, and will remove all the data tied to the workspace.
          </ModalBody>
          <ModalFooter>
            <Button style={{ marginRight: '15px' }} onClick={() => setDeleteWorkspaceModal(!deleteWorkspaceModal)}>
              Close
            </Button>
            <Button onClick={handleDeleteSubmit}>Delete</Button>
          </ModalFooter>
        </Modal>
      )}
      {workspaceInviteUser && (
        <WorkspaceInvite onClose={() => setWorkspaceInviteUser(!workspaceInviteUser)} workspace={workspace} />
      )}
      {createDashboard && (
        <CreateDashboard onClose={() => setCreateDashboard(!createDashboard)} workspace={workspace} />
      )}
      {dashboardAddUser && (
        <DashboardAddUser
          users={users}
          workspace={workspace}
          onClose={() => setDashboardAddUser(!dashboardAddUser)}
          dashboard={selectedDashboard}
        />
      )}
      <div className={styles.admin}>
        <Heading style={{ width: '450px' }}>
          <HeadingBody style={{ paddingBottom: '0px' }}>
            <HeadingTitle>{workspace && workspace.company}&apos;s Workspace</HeadingTitle>
          </HeadingBody>
        </Heading>

        <div className={styles.adminBody}>
          <div className={styles.adminBodyIcon}>
            <img src={workspace && workspace.icon ? workspace.icon : LogoMiniColored} />
          </div>

          <Tabs>
            <Tab title="General">
              <>
                <div className={styles.adminBodyActions}>
                  {/* <Button variant={Variants.OUTLINED} label="Update License" /> */}
                  <Button onClick={() => setColorWorkspaceModal(!colorWorkspaceModal)}>Change Color</Button>
                  <Button onClick={() => setDeleteWorkspaceModal(!deleteWorkspaceModal)}>Delete Workspace</Button>
                </div>
                <div className={styles.adminBodyContent}>
                  <Row>
                    <RowColumn>
                      <span className={styles.adminBodyContentKey}>Name:</span>
                      <span className={styles.adminBodyContentKey}>Workspace Owner:</span>
                      <span className={styles.adminBodyContentKey}>Color:</span>
                      <span className={styles.adminBodyContentKey}>Created At:</span>
                      <span className={styles.adminBodyContentKey}>Users:</span>
                    </RowColumn>
                    <RowColumn>
                      <span className={styles.adminBodyContentValue}>{workspace ? workspace.company : '--'}</span>
                      <span className={styles.adminBodyContentValue}>{owner ? owner.email : '--'}</span>
                      <span
                        className={styles.adminBodyContentValue}
                        style={{ color: `#${workspace && workspace.color}` }}
                      >
                        {workspace ? '#' + workspace.color : '--'}
                      </span>
                      <span className={styles.adminBodyContentValue}>
                        {workspace ? convertDate(workspace.createdAt) : '--'}
                      </span>
                      <span className={styles.adminBodyContentValue}>
                        <Progressbar percentage={workspace && (workspace.users.length / workspace.maxUsers) * 100}>
                          {workspace && `${workspace.users.length}/${workspace.maxUsers}`}
                        </Progressbar>
                      </span>
                    </RowColumn>
                  </Row>
                </div>
              </>
            </Tab>
            <Tab title="Users">
              {users && (
                <>
                  <div className={styles.adminBodyActions}>
                    <Button onClick={() => setWorkspaceInviteUser(!workspaceInviteUser)}>Invite User</Button>
                  </div>
                  <div className={styles.adminBodyContent}>
                    <Table>
                      <Thead>
                        <Tr>
                          {userColumns.map((col, idx: number) => {
                            return (
                              <Th data-label={col} key={`col-${col.column}-${idx}`}>
                                {col.label}
                              </Th>
                            );
                          })}
                        </Tr>
                      </Thead>
                      <Tbody>
                        {users &&
                          users.map((user: IUser, idx) => {
                            return (
                              <Tr data-label={user.email} key={`user-${user.email}-${idx}`}>
                                <Td>{user.firstName}</Td>
                                <Td>{user.lastName}</Td>
                                <Td>{user.email}</Td>
                              </Tr>
                            );
                          })}
                      </Tbody>
                    </Table>
                  </div>
                </>
              )}
            </Tab>
            <Tab title="Dashboard">
              {workspace && (
                <>
                  <div className={styles.adminBodyActions}>
                    <Button onClick={() => setCreateDashboard(!createDashboard)}>New Dashboard</Button>
                  </div>
                  <div className={styles.adminBodyContent}>
                    {workspace && workspace.dashboards.length > 0 ? (
                      <Table>
                        <Thead>
                          <Tr>
                            {dashboardColumns.map((col, idx: number) => {
                              return (
                                <Th data-label={col} key={`col-${col.column}-${idx}`}>
                                  {col.label}
                                </Th>
                              );
                            })}
                          </Tr>
                        </Thead>
                        <Tbody>
                          {workspace &&
                            workspace.dashboards.map((dashboard: IDashboard, idx) => {
                              return (
                                <Tr
                                  onClick={() => {
                                    setSelectedDashboard(dashboard);
                                    setDashboardAddUser(!dashboardAddUser);
                                  }}
                                  data-label={dashboard.id}
                                  key={`dashboard-${dashboard.id}-${idx}`}
                                >
                                  <Td>{dashboard.type}</Td>
                                  <Td>{dashboard.subType ? dashboard.subType : '--'}</Td>
                                  <Td>{dashboard.allowedUsers.length}</Td>
                                  <Td>{convertDate(dashboard.createdAt)}</Td>
                                </Tr>
                              );
                            })}
                        </Tbody>
                      </Table>
                    ) : (
                      <span>No dashboard attached to this workspace.</span>
                    )}
                  </div>
                </>
              )}
            </Tab>
          </Tabs>
        </div>
      </div>
    </AppContent>
  );
};

export default AdminWorkspaceScreen;
