export interface IUser {
  _id: string;
  email: string;
  firstName: string;
  lastName: string;
  isVerified: boolean;
  role: Roles;
  createdAt: Date;
  workspaces: { workspaceId: string }[];
}

export enum Roles {
  DEFAULT = 'default',
  WORKSPACE_USER = 'workspace_user',
  WORKSPACE_ADMIN = 'workspace_admin',
  ADMIN = 'admin',
  SUPER_ADMIN = 'super_admin',
}
