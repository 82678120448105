import React, { useEffect, useState } from 'react';
import { Embed, models, Report, IEmbedConfiguration, Page } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import { DashboardType, IDashboard } from '~/models/IDashboard';
import { Card, CardBody, CardHeader } from '~/components/Card';
import Button from '~/components/Button';
import styles from './styles.scss';
import classNames from 'classnames';
import LogoMiniColored from '~/assets/logo_mini_colored.png';
import { getLocalStorage } from '~/services/localstorage-service';

interface IProps {
  accessToken: string;
}

const prettifyType = (type: DashboardType): string => {
  return DashboardType[type.toString() as keyof typeof DashboardType];
};

const DashboardCard: React.FC<IProps & IDashboard> = ({
  id,
  type,
  workspaceColor,
  subType,
  embedUrl,
  workspaceIcon,
  accessToken,
}: IProps & IDashboard) => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isShow, setShow] = useState<boolean>(false);
  const [pages, setPages] = useState<Array<Page>>([]);
  const [report, setReport] = useState<Report>();
  const [reportConfig, setReportConfig] = useState<IEmbedConfiguration>({
    type: 'report',
    id: id,
    embedUrl: embedUrl,
    accessToken: accessToken,
    settings: {
      background: models.BackgroundType.Transparent,
      navContentPaneEnabled: false,
      panes: {
        filters: {
          expanded: false,
          visible: false,
        },
      },
    },
  });

  useEffect(() => {
    if (isLoading) {
      return;
    }

    report.getPages().then(element => {
      console.log(element);
      setPages(pages => pages.concat(element));
    });
  }, [isLoading]);

  const eventHandlersMap = new Map([
    [
      'loaded',
      () => {
        setLoading(!isLoading);
      },
    ],
  ]);

  const handleClick = () => {
    setShow(!isShow);
  };

  return (
    <>
      {isShow && (
        <div className={classNames(styles.modal, { [styles.modalLarge]: getLocalStorage('navigation') })}>
          <div className={styles.modalHeader}>
            <Button type="red" onClick={handleClick}>
              Close
            </Button>
          </div>
          <div className={styles.modalContainer}>
            <PowerBIEmbed
              embedConfig={reportConfig}
              eventHandlers={eventHandlersMap}
              cssClassName={styles.modalContainerFrame}
              getEmbeddedComponent={(embedObject: Embed) => {
                setReport(embedObject as Report);
              }}
            />
          </div>
          <div className={styles.modalFooter}>
            {pages.map((page, idx) => (
              <Button key={`Page_${idx}`} onClick={() => report.setPage(page.name)}>
                {idx}
              </Button>
            ))}
          </div>
        </div>
      )}
      <Card className={styles.card} onClick={handleClick}>
        <CardHeader
          className={styles.cardHeader}
          style={{
            background: `linear-gradient(to bottom, #${workspaceColor} 50%, white 0%)`,
          }}
        >
          <div className={styles.cardHeaderIcon}>
            <img src={workspaceIcon ? workspaceIcon : LogoMiniColored} />
          </div>
        </CardHeader>
        <CardBody border={false} className={styles.cardBody}>
          <h1 className={styles.cardBodyTitle}>{prettifyType(type)}</h1>
          <h2 className={classNames(styles.cardBodyTitle, styles.cardBodyTitleSub)}>{subType}</h2>
          <p className={styles.cardBodyText}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer purus arcu, vulputate vel finibus
            vestibulum,
          </p>
        </CardBody>
      </Card>
    </>
  );
};

export default DashboardCard;
