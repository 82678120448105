import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import AppContent from '~/components/AppContent';
import { getAllWorkspaces, getDashboard, getWorkspace } from '~/services/workspace-service';
import { IWorkspace } from '~/models/IWorkspace';
import WorkspaceCard from '../components/WorkspaceCard';
import { IDashboard } from '~/models/IDashboard';
import DashboardCard from '../components/DashboardCard';
import styles from '../styles.scss';
import { Routes } from '~/routes/Routes';
import { history } from '~/store';
import { Heading, HeadingBody, HeadingTitle } from '../../../components/Heading';

export const DashboardsScreen: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [data, setData] = useState<Array<IDashboard>>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [accessToken, setAccessToken] = useState<string>('');
  const [workspaceName, setWorkspaceName] = useState<string>('');

  useEffect(() => {
    getDashboard(id)
      .then(response => {
        const dashboard: Array<IDashboard> = response.data;
        setLoading(!isLoading);

        if (response.success) {
          setAccessToken(response.token);
          console.log(dashboard);
          setData(dashboard);
        }
      })
      .catch(() => {
        setError(true);
        setErrorMessage('There seems to be a issue while loading your data.');
        setLoading(!isLoading);
      });
  }, []);

  useEffect(() => {
    getWorkspace(id)
      .then(response => {
        const workspace = response.data;

        if (response.success) {
          setWorkspaceName(workspace.name);
        } else {
          history.push(Routes.DASHBOARDING);
        }
      })
      .catch(() => {
        setError(true);
      });
  }, [workspaceName]);

  const renderDashboards = () => {
    if (isLoading) return <div className={styles.notice}>Loading...</div>;

    if (error) return <div className={styles.notice}>{errorMessage}</div>;

    if (data.length <= 0) return <div className={styles.notice}>You don&apos;t have any dashboard(s) available.</div>;

    return data.map(({ type, subType, id, workspaceColor, embedUrl, workspaceIcon }: IDashboard, idx: number) => {
      return (
        <DashboardCard
          key={`dashboardCard-${idx}`}
          id={id}
          embedUrl={embedUrl}
          type={type}
          subType={subType}
          workspaceColor={workspaceColor}
          accessToken={accessToken}
          workspaceIcon={workspaceIcon}
        />
      );
    });
  };

  return (
    <AppContent center={false}>
      <div className={classNames(styles.thx)}>
        <div className={styles.thxContent}>
          <div className={classNames(styles.thxContentTitle)}>{workspaceName}</div>
          <div className={classNames(styles.thxContentTitle, styles.thxContentTitleSub)}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum malesuada erat et pharetra accumsan.
            Phasellus semper nibh non ultricies placerat. Mauris in mi vel nibh viverra elementum ut at augue.{' '}
          </div>
        </div>
        <div className={classNames(styles.cards, styles.cardsCenter)}>{renderDashboards()}</div>
      </div>
    </AppContent>
  );
};

export const WorkspaceScreen: React.FC = () => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [data, setData] = useState<Array<IWorkspace>>([]);

  useEffect(() => {
    getAllWorkspaces()
      .then(response => {
        if (response.success) {
          console.log(response);
          setLoading(!isLoading);
          setData(response.workspaces);
        } else {
          history.push(Routes.DASHBOARDING);
        }
      })
      .catch(() => {
        setError(!error);
        setErrorMessage('There seems to be a issue while loading your data.');
        setLoading(!isLoading);
      });
  }, []);

  const renderWorkspaces = () => {
    if (isLoading) return <div className={styles.notice}>Loading...</div>;

    if (error) return <div className={styles.notice}>{errorMessage}</div>;

    if (data.length === 0) return <div className={styles.notice}>Your account does not have any dashboards.</div>;

    return data.map(({ _id, company, color, icon }: IWorkspace, idx: number) => {
      return (
        <WorkspaceCard
          key={`workspaceCard-${idx}`}
          name={company}
          icon={icon}
          color={color}
          to={`${Routes.DASHBOARDING}/${_id}`}
        />
      );
    });
  };

  return (
    <AppContent center={false}>
      <div className={classNames(styles.thx)}>
        <Heading>
          <HeadingBody>
            <HeadingTitle>Dashboards</HeadingTitle>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum malesuada erat et pharetra accumsan.
            Phasellus semper nibh non ultricies placerat. Mauris in mi vel nibh viverra elementum ut at augue.{' '}
          </HeadingBody>
        </Heading>
        <div className={classNames(styles.cards, styles.cardsCenter)}>{renderWorkspaces()}</div>
      </div>
    </AppContent>
  );
};
