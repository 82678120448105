import { AxiosError, AxiosResponse } from 'axios';
import { IDashboards } from '~/models/IDashboard';
import { IRequest } from '~/models/IRequest';
import axios from '~/lib/axios-client';
import { Auth } from '~/lib/auth-lib';
import { IWorkspaceRequest } from '~/models/IWorkspace';

export const getWorkspace = async (workspaceId: string): Promise<IWorkspaceRequest> => {
  return await axios
    .get(`/v1/workspace/${workspaceId}`, {
      headers: {
        Authorization: `Bearer ${Auth.getToken()}`,
      },
    })
    .then((res: AxiosResponse) => {
      const data: IRequest = res.data;

      if (data.success) {
        return data;
      }
    })
    .catch((err: AxiosError) => {
      if (err.response) {
        return err.response.data;
      }
    });
};

export const getAllWorkspaces = async (): Promise<any> => {
  return await axios
    .get(`/v1/workspace`, {
      headers: {
        Authorization: `Bearer ${Auth.getToken()}`,
      },
    })
    .then((res: AxiosResponse) => {
      const data: IRequest = res.data;

      if (data.success) {
        return data;
      }
    })
    .catch((err: AxiosError) => {
      if (err.response) {
        return err.response.data;
      }
    });
};

export const getAllDashboard = async (): Promise<IDashboards> => {
  return await axios
    .get(`/v1/workspace/dashboards`, {
      headers: {
        Authorization: `Bearer ${Auth.getToken()}`,
      },
    })
    .then((res: AxiosResponse) => {
      const data: IRequest = res.data;

      if (data.success) {
        return data;
      }
    })
    .catch((err: AxiosError) => {
      if (err.response) {
        return err.response.data;
      }
    });
};

export const getDashboard = async (workspaceId: string): Promise<IDashboards> => {
  return await axios
    .get(`/v1/workspace/dashboards/${workspaceId}`, {
      headers: {
        Authorization: `Bearer ${Auth.getToken()}`,
      },
    })
    .then((res: AxiosResponse) => {
      const data: IRequest = res.data;

      if (data.success) {
        return data;
      }
    })
    .catch((err: AxiosError) => {
      if (err.response) {
        return err.response.data;
      }
    });
};

export const getToken = async (): Promise<IRequest> => {
  return await axios
    .get(`/v1/workspace/dashboards/token`, {
      headers: {
        Authorization: `Bearer ${Auth.getToken()}`,
      },
    })
    .then((res: AxiosResponse) => {
      const data: IRequest = res.data;

      if (data.success) {
        return data;
      }
    })
    .catch((err: AxiosError) => {
      if (err.response) {
        return err.response.data;
      }
    });
};
