import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { LogoLight, SettingsIcon, NotificationIcon, LogoutIcon, LogoLightMini } from '~/components/Icons';
import { Auth } from '~/lib/auth-lib';
import { Routes } from '~/routes/Routes';
import { connect } from 'react-redux';
import { IRootState } from '~/store';
import styles from './styles.scss';

interface IStoreProps {
  isCollapsed: boolean;
}

const Header = ({ isCollapsed }: IStoreProps) => {
  return (
    <header className={styles.header}>
      <div
        className={classNames(styles.headerLogo, {
          [styles.headerLogoMinimize]: isCollapsed,
        })}
      >
        <Link to={'/app'}>
          <img
            className={isCollapsed ? styles.headerLogoSmall : styles.headerLogoLarge}
            src={isCollapsed ? LogoLightMini : LogoLight}
          />
        </Link>
      </div>
      <div className={styles.headerUser}>
        <div className={styles.headerUserPreferences}>
          <ul>
            <li>
              <Link to={Routes.USER_SETTINGS}>
                <img src={SettingsIcon} />
              </Link>
            </li>
            <li>
              <Link to={Routes.LOGOUT}>
                <img src={LogoutIcon} />
              </Link>
            </li>
          </ul>
        </div>
        <div className={styles.headerUserProfile}>
          <ul>
            <li>{Auth.getUserFullName()}</li>
          </ul>
          <div className={styles.headerUserProfileIcon}>
            <div className={styles.headerUserProfileIconInitials}>{Auth.getUserInitials()}</div>
          </div>
        </div>
      </div>
    </header>
  );
};

const mapStateToProps = ({ navigation }: IRootState) => ({
  isCollapsed: navigation.isCollapsed,
});

export default connect(mapStateToProps, null)(Header);
