import React, { useState, useContext, useCallback } from 'react';
import ToastContainer from './ToastContainer';

const ToastContext = React.createContext(null);

let id = 0;

enum ToastVariants {
  SUCCESS = 'success',
  DANGER = 'error',
  INFO = 'info',
  WARNING = 'warning',
}

interface IProps {
  children: React.ReactNode;
}

const ToastProvider = ({ children }: IProps) => {
  const [toasts, setToasts] = useState([]);

  const addToast = useCallback(
    (children, variant: ToastVariants, dismissTime: number) => {
      setToasts(toasts => [
        ...toasts,
        {
          id: id++,
          children,
          variant,
          dismissTime,
        },
      ]);
    },
    [setToasts],
  );

  const removeToast = useCallback(
    id => {
      setToasts(toasts => toasts.filter(toast => toast.id !== id));
    },
    [setToasts],
  );

  return (
    <ToastContext.Provider
      value={{
        addToast,
        removeToast,
      }}
    >
      <ToastContainer toasts={toasts} />
      {children}
    </ToastContext.Provider>
  );
};

const useToast = () => {
  const toastHelpers = useContext(ToastContext);

  return toastHelpers;
};

export { ToastContext, useToast, ToastVariants };
export default ToastProvider;
