import React from 'react';
import classNames from 'classnames';
import styles from '../styles.scss';

interface IProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}

const Table: React.FC<IProps> = ({ children, style, className }: IProps) => {
  const newClasses = classNames(styles.table, {
    className,
  });

  return (
    <div role="table-wrapper" className={styles.wrapper}>
      <table role="table" className={newClasses} style={style}>
        {children}
      </table>
    </div>
  );
};

export default Table;
