import React from 'react';
import { Card, CardBody, CardHeader } from '~/components/Card';
import styles from './styles.scss';
import LogoMiniColored from '~/assets/logo_mini_colored.png';
import { Link } from 'react-router-dom';

interface IProps {
  name: string;
  icon: string;
  color: string;
  to?: string;
}

const WorkspaceCard: React.FC<IProps> = ({ name, icon, color, to }) => {
  const renderCard = () => {
    return (
      <Card className={styles.card}>
        <CardHeader
          className={styles.cardHeader}
          style={{
            background: `linear-gradient(to bottom, #${color} 50%, white 0%)`,
          }}
        >
          <div className={styles.cardHeaderIcon}>
            <img src={icon ? icon : LogoMiniColored} />
          </div>
        </CardHeader>
        <CardBody style={{ borderTop: 'none' }} className={styles.cardBody}>
          <h1 className={styles.cardBodyTitle}>{name}</h1>
          <p className={styles.cardBodyText}>Click to see the dashboard(s).</p>
        </CardBody>
      </Card>
    );
  };

  return (
    <>
      {to && <Link to={{ pathname: to }}>{renderCard()}</Link>}
      {!to && renderCard()}
    </>
  );
};

export default WorkspaceCard;
