import React, { useState } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { RegisterAccountIcon } from '../../../components/Icons';
import Input from '~/components/Input';
import Button from '~/components/Button';
import { Routes } from '~/routes/Routes';
import { register, IRegisterUser } from '../actions/register-actions';
import PasswordStrengthBar from '~/components/PasswordStrengthBar';
import styles from '../styles.scss';
import { connect } from 'react-redux';
import { IRootState } from '~/store';
import AuthContent from '~/components/AuthContent';
import { IRequestError } from '~/models/IRequest';

interface IStoreProps {
  register: (user: IRegisterUser) => void;
  isLoading: boolean;
  errors: Array<IRequestError>;
}

const Register = ({ register, isLoading, errors }: IStoreProps) => {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');

  const onSubmit = (event: React.FormEvent<HTMLButtonElement>) => {
    if (isLoading) return;
    event.preventDefault();

    const user: IRegisterUser = {
      firstName,
      lastName,
      email,
      password,
      passwordConfirm,
    };

    return register(user);
  };

  return (
    <AuthContent>
      <div className={styles.register}>
        <div className={styles.registerLeft}>
          <div className={styles.registerLeftText}>
            <h1 className={classNames(styles.registerLeftTextTitle)}>Create your account</h1>
            <p className={styles.registerLeftTextTooltip}>
              Already have an account?{' '}
              <Link to={Routes.LOGIN}>
                <span>Login</span>
              </Link>
            </p>
          </div>
          <form className={styles.registerLeftForm}>
            <div className={classNames(styles.registerLeftInline)}>
              <Input
                onChange={({ value }) => setFirstName(value)}
                type="text"
                placeholder="John"
                errors={errors}
                leftLabel="First Name"
                name="firstName"
              />
            </div>

            <div className={classNames(styles.registerLeftInline, styles.registerLeftInlineRight)}>
              <Input
                onChange={({ value }) => setLastName(value)}
                type="text"
                placeholder="Doe"
                errors={errors}
                leftLabel="Last Name"
                name="lastName"
              />
            </div>

            <Input
              onChange={({ value }) => setEmail(value)}
              type="email"
              placeholder="name@work-email.com"
              leftLabel="Email"
              errors={errors}
              name="email"
            />

            <div className={classNames(styles.registerLeftInline)}>
              <Input
                onChange={({ value }) => setPassword(value)}
                type="password"
                placeholder="*****"
                leftLabel="Password"
                name="password"
                errors={errors}
              />
            </div>

            <div className={classNames(styles.registerLeftInline, styles.registerLeftInlineRight)}>
              <Input
                onChange={({ value }) => setPasswordConfirm(value)}
                type="password"
                placeholder="*****"
                leftLabel="Confirm"
                name="passwordConfirm"
                errors={errors}
              />
            </div>

            <PasswordStrengthBar password={password} />

            <Button onClick={onSubmit} isLoading={isLoading} className={styles.button}>
              Create my account
            </Button>
          </form>
        </div>
        <div className={styles.registerRight}>
          <img className={styles.registerRightIcon} src={RegisterAccountIcon} />
        </div>
      </div>
    </AuthContent>
  );
};

const mapStateToProps = ({ register }: IRootState) => ({
  isLoading: register.isLoading,
  errors: register.errors,
});

export default connect(mapStateToProps, { register })(Register);
