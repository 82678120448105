import React from 'react';
import { Redirect, Route, RouteProps, Switch } from 'react-router-dom';
import { Routes } from './Routes';
import { Roles } from '~/models/IUser';
import { Auth } from '~/lib/auth-lib';
import { HomeScreen, NotFoundScreen } from '~/modules/App';
import { LoginScreen, LogoutScreen, ForgotPasswordScreen, VerifyScreen, RegisterScreen } from '~/modules/Auth';
import {
  AppScreen,
  DashboardsScreen,
  FileShareScreen,
  ReportsScreen,
  ToolsScreen,
  QuotaScreen,
  UserSettingsScreen,
  WorkspaceScreen,
} from '~/modules/Dashboard';
import { AdminScreen, AdminUsersScreen, AdminWorkspaceScreen, AdminWorkspacesScreen } from '~/modules/Admin';

export const AppRoutes: React.FC = () => {
  return (
    <Switch>
      <Route exact path={Routes.BASE} component={HomeScreen} />

      <PublicRoute path={Routes.LOGIN} component={LoginScreen} />
      <PublicRoute path={Routes.REGISTER} component={RegisterScreen} />
      <PublicRoute path={Routes.FORGOT_PASSWORD} component={ForgotPasswordScreen} />
      <PublicRoute path={Routes.VERIFY} component={VerifyScreen} />

      <PrivateRoute exact path={Routes.APP} component={AppScreen} />
      <PrivateRoute exact path={Routes.DASHBOARDING} component={WorkspaceScreen} />
      <PrivateRoute exact path={`${Routes.DASHBOARDING}/:id`} component={DashboardsScreen} />
      <PrivateRoute exact path={Routes.FILESHARE} component={FileShareScreen} />
      <PrivateRoute exact path={Routes.USER_SETTINGS} component={UserSettingsScreen} />
      <PrivateRoute exact path={Routes.TOOLS} component={ToolsScreen} />
      <PrivateRoute exact path={Routes.QUOTA} component={QuotaScreen} />

      <PrivateRoute exact path={Routes.REPORTS} component={ReportsScreen} />

      <PrivateRoute exact path={Routes.ADMIN} roles={[Roles.ADMIN, Roles.SUPER_ADMIN]} component={AdminScreen} />
      <PrivateRoute
        exact
        path={Routes.ADMIN_USERS}
        roles={[Roles.ADMIN, Roles.SUPER_ADMIN]}
        component={AdminUsersScreen}
      />
      <PrivateRoute
        exact
        path={Routes.ADMIN_WORKSPACES}
        roles={[Roles.ADMIN, Roles.SUPER_ADMIN]}
        component={AdminWorkspacesScreen}
      />
      <PrivateRoute
        exact
        path={`${Routes.ADMIN_WORKSPACES}/:id`}
        roles={[Roles.ADMIN, Roles.SUPER_ADMIN]}
        component={AdminWorkspaceScreen}
      />

      <PrivateRoute path={Routes.LOGOUT} component={LogoutScreen} />

      <Route component={NotFoundScreen} />
    </Switch>
  );
};

type RouteRoleProps = RouteProps & { roles?: Roles[] };

export const PrivateRoute: React.FC<RouteRoleProps> = ({ component: Component, roles, ...rest }: RouteRoleProps) => (
  <Route
    {...rest}
    render={props => {
      const isAuth: boolean = Auth.isUserAuthenticated();

      if (!isAuth) {
        return <Redirect to={`/login${props.location.pathname !== '/login' && '?from=' + props.location.pathname}`} />;
      }

      const currentRole: Roles = Auth.getUser().role;

      // Check if route is restricted by role
      if (roles && roles.indexOf(currentRole) === -1) {
        // Role is not authorised so redirect to logout
        return <Redirect to={Routes.LOGOUT} />;
      }

      return <Component {...props} {...rest} />;
    }}
  />
);

export const PublicRoute: React.FC<RouteProps> = ({ component: Component, ...rest }: RouteProps) => (
  <Route
    {...rest}
    render={props =>
      Auth.isUserAuthenticated() ? (
        <Redirect
          to={{
            pathname: Routes.APP,
            state: { from: props.location },
          }}
        />
      ) : (
        <Component {...props} {...rest} />
      )
    }
  />
);
