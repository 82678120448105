import React, { useEffect, useState } from 'react';
import AppContent from '~/components/AppContent';
import classNames from 'classnames';
import styles from '../styles.scss';
import { IUser } from '~/models/IUser';
import { getUser } from '~/services/user-service';
import { Heading, HeadingBody, HeadingTitle } from '../../../components/Heading';

const ReportsScreen: React.FC = () => {
  const [user, setUser] = useState<IUser | undefined>(undefined);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    getUser()
      .then(response => {
        setUser(response.user);
        setLoading(!isLoading);
      })
      .catch(() => {
        setError(true);
        setLoading(!isLoading);
      });
  }, []);

  const renderReports = () => {
    if (isLoading) {
      return <span className={styles.notice}>Loading...</span>;
    }

    if (error) {
      return <span className={styles.notice}>Error occurred loading &apos;DEEP&apos; services.</span>;
    }

    if (user == undefined || (user && user.workspaces.length <= 0)) {
      return <span className={styles.notice}>Your account does not have any reports.</span>;
    }

    return <span>{'{failed to load reports}'}</span>;
  };

  return (
    <AppContent center={false}>
      <div className={classNames(styles.reports)}>
        <Heading>
          <HeadingBody>
            <HeadingTitle>Reports</HeadingTitle>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum malesuada erat et pharetra accumsan.
            Phasellus semper nibh non ultricies placerat. Mauris in mi vel nibh viverra elementum ut at augue.
          </HeadingBody>
        </Heading>

        {renderReports()}
      </div>
    </AppContent>
  );
};

export default ReportsScreen;
