import { AxiosError, AxiosResponse } from 'axios';
import { IRequest } from '~/models/IRequest';
import axios from '~/lib/axios-client';
import { Auth } from '~/lib/auth-lib';
import { IQuota, IQuotaFilter, IQuotaRequest } from '~/models/IQuota';

export const getQuotasService = async (
  page: number,
  limit: number,
  filters: IQuotaFilter,
  orderBy: { [key: string]: string },
): Promise<IQuotaRequest> => {
  let queryString: string = `?limit=${limit}&page=${page}`;
  if (filters) {
    for (const [key, value] of Object.entries(filters)) {
      if (!value) continue;
      if (Array.isArray(value)) {
        queryString += `&${key}=${value.join(';')}`;
        continue;
      }
      queryString += `&${key}=${value}`;
    }
  }
  if (orderBy) {
    for (const [key, value] of Object.entries(orderBy)) {
      queryString += `&orderBy[${key}]=${value}`;
    }
  }
  const url = `/v1/quotas${queryString}`;
  console.log('calling ' + url);

  return await axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${Auth.getToken()}`,
      },
    })
    .then((res: AxiosResponse) => {
      const data: IRequest = res.data;

      if (data.success) {
        return data;
      }
    })
    .catch((err: AxiosError) => {
      if (err.response) {
        return err.response.data;
      }
    });
};

export const saveQuotaService = async (quota: IQuota): Promise<boolean> => {
  return await axios
    .post(`/v1/quotas/create`, {
      headers: {
        Authorization: `Bearer ${Auth.getToken()}`,
      },
      ...quota,
    })
    .then((res: AxiosResponse) => {
      const data: IRequest = res.data;

      if (data.success) return true;
      return false;
    })
    .catch((err: AxiosError) => {
      if (err.response) {
        return err.response.data;
      }
    });
};

export const updateQuotaService = async (quota: IQuota): Promise<boolean> => {
  return await axios
    .put(`/v1/quotas/edit`, {
      headers: {
        Authorization: `Bearer ${Auth.getToken()}`,
      },
      ...quota,
    })
    .then((res: AxiosResponse) => {
      const data: IRequest = res.data;

      if (data.success) return true;
      return false;
    })
    .catch((err: AxiosError) => {
      if (err.response) {
        return err.response.data;
      }
    });
};

export const deleteQuotaService = async (quotaCode: string): Promise<boolean> => {
  return await axios
    .delete(`/v1/quotas/delete/${quotaCode}`, {
      headers: {
        Authorization: `Bearer ${Auth.getToken()}`,
      },
    })
    .then((res: AxiosResponse) => {
      const data: IRequest = res.data;

      if (data.success) return true;
      return false;
    })
    .catch((err: AxiosError) => {
      if (err.response) {
        return err.response.data;
      }
    });
};
