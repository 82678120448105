import React, { useState } from 'react';
import Button from '~/components/Button';
import Input from '~/components/Input';
import { Modal, ModalBody, ModalFooter, ModalTitle } from '~/components/Modal';
import { useToast, ToastVariants } from '~/components/Toast';
import { create } from '~/services/users-service';

interface IProps {
  onClose: () => void;
}

const CreateUser: React.FC<IProps> = ({ onClose }: IProps) => {
  const [email, setEmail] = useState<string>();
  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const { addToast } = useToast();

  const handleSubmit = async () => {
    const body = {
      email,
      firstName,
      lastName,
    };

    console.log('create user. %o', body);
    await create(body)
      .then(data => {
        if (data.success) {
          console.log(data);
          onClose && onClose();
          return addToast('Created a new User.');
        }

        addToast('Failed to create the User', ToastVariants.DANGER);
      })
      .catch(err => {
        console.error(err);
      });
  };

  return (
    <Modal onClose={onClose}>
      <ModalTitle>Create New User</ModalTitle>
      <ModalBody>
        <Input style={{ width: '80%' }} name="email" placeholder="Email" onChange={({ value }) => setEmail(value)} />
        <Input
          style={{ width: '80%' }}
          name="firstName"
          placeholder="First Name"
          onChange={({ value }) => setFirstName(value)}
        />
        <Input
          style={{ width: '80%' }}
          name="lastName"
          placeholder="Last Name"
          onChange={({ value }) => setLastName(value)}
        />
      </ModalBody>
      <ModalFooter style={{ justifyContent: 'flex-end' }}>
        <Button style={{ marginRight: '15px' }} onClick={onClose}>
          Close
        </Button>
        <Button onClick={handleSubmit}>Save changes</Button>
      </ModalFooter>
    </Modal>
  );
};

export default CreateUser;
