import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import styles from './styles.scss';
import { IRequestError } from '~/models/IRequest';

type InputTypes =
  | 'button'
  | 'checkbox'
  | 'color'
  | 'date'
  | 'datetime-local'
  | 'email'
  | 'file'
  | 'hidden'
  | 'image'
  | 'month'
  | 'number'
  | 'password'
  | 'radio'
  | 'range'
  | 'reset'
  | 'search'
  | 'submit'
  | 'tel'
  | 'text'
  | 'time'
  | 'url'
  | 'week';

interface IProps {
  name: string;
  id?: string;
  type?: InputTypes;
  placeholder: string;
  leftLabel?: string;
  rightLabel?: string;
  tabIndex?: number;
  fileAccept?: string;
  href?: string;
  groupClassName?: string;
  labelClassName?: string;
  inputClassName?: string;
  readOnly?: boolean;
  disabled?: boolean;
  errors?: Array<IRequestError> | undefined;
  maxLength?: number;
  value?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
  onEnter?: ({ event }: { event: React.KeyboardEvent<HTMLDivElement> }) => void;
  onChange?: ({
    value,
    name,
    event,
  }: {
    value: string;
    name: string;
    event: React.ChangeEvent<HTMLInputElement>;
  }) => void;
}

const Input = ({
  name,
  style,
  type = 'text',
  placeholder,
  leftLabel,
  rightLabel,
  tabIndex,
  href,
  fileAccept,
  groupClassName,
  labelClassName,
  inputClassName,
  readOnly = false,
  disabled = false,
  errors,
  maxLength = 64,
  value,
  onClick,
  onEnter,
  onChange,
}: IProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    onChange && onChange({ value, name, event });
  };

  const handleEnter = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      onEnter && onEnter({ event });
    }
  };

  const newGroupClassName = classNames(styles.group, groupClassName);

  const newLabelClassName = classNames(styles.groupLeftLabel, labelClassName, {
    [styles.inputError]: errors != undefined,
  });

  const newInputClassName = classNames(styles.input, inputClassName, {
    [styles.inputReadonly]: readOnly,
    [(styles.inputError, styles.inputErrorBorder)]: errors != undefined,
  });

  const props = {
    placeholder,
    value,
    readOnly,
    type,
    maxLength,
    tabIndex,
    disabled,
    className: newInputClassName,
    onChange: handleChange,
  };

  const findErrorMatch = () => {
    if (errors == undefined || !errors) {
      return;
    }

    const isFound: IRequestError = errors.find(result => {
      if (result.code == name) {
        return result;
      }
    });

    if (isFound) {
      const cleanedMessage: string = isFound.error.replace(/['"]+/g, '');

      return cleanedMessage.charAt(0).toUpperCase() + cleanedMessage.slice(1);
    }
  };

  return (
    <div className={newGroupClassName}>
      {leftLabel && <label className={newLabelClassName}>{leftLabel}</label>}
      {rightLabel && (
        <label className={classNames(styles.groupRightLabel)}>
          <Link to={href}>{rightLabel}</Link>
        </label>
      )}
      <input onKeyDown={handleEnter} accept={fileAccept} style={style} onClick={onClick} {...props} type={type} />
      <div className={styles.errorMessage}>{findErrorMatch()}</div>
    </div>
  );
};

export default Input;
