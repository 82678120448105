import React from 'react';
import classNames from 'classnames';
import styles from '../styles.scss';

interface IProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  onClick?: () => void;
}

const Tr: React.FC<IProps> = ({ children, style, className, onClick }: IProps) => {
  return (
    <tr onClick={onClick} className={classNames(styles.tableTr, className)} style={style}>
      {children}
    </tr>
  );
};

export default Tr;
