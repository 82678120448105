import React, { useState } from 'react';
import Button from '~/components/Button';
import Input from '~/components/Input';
import { Modal, ModalBody, ModalFooter, ModalTitle } from '~/components/Modal';
import Select from '~/components/Select';
import { useToast } from '~/components/Toast';
import { DashboardType } from '~/models/IDashboard';
import { IWorkspace } from '~/models/IWorkspace';
import { addDashboard } from '~/services/dashboards-service';

interface IProps {
  onClose: () => void;
  workspace: IWorkspace;
}

const CreateDashboard: React.FC<IProps> = ({ onClose, workspace }: IProps) => {
  const [reportId, setReportId] = useState<string>();
  const [type, setType] = useState<string>();
  const [subType, setSubType] = useState<string>();
  const { addToast } = useToast();

  const handleSubmit = async () => {
    console.log('#submit');

    addDashboard(reportId, type, workspace._id, subType)
      .then(res => {
        console.log(res);
      })
      .catch(err => {
        console.error(err);
      });
  };

  return (
    <Modal onClose={onClose}>
      <ModalTitle>Attach Dashboard to {workspace.company}</ModalTitle>
      <ModalBody>
        <Input style={{ width: '100%' }} readOnly={true} placeholder={workspace.company} name="workspace" />
        <Input
          onChange={({ value }) => setReportId(value)}
          maxLength={36}
          style={{ width: '100%' }}
          placeholder="PowerBI Report Id..."
          name="reportId"
        />
        <Select options={Object.keys(DashboardType).map(type => type)} onChange={({ selected }) => setType(selected)} />
        <Input
          onChange={({ value }) => setSubType(value)}
          maxLength={36}
          style={{ width: '100%' }}
          placeholder="Sub Type"
          name="subType"
        />
      </ModalBody>
      <ModalFooter style={{ justifyContent: 'flex-end' }}>
        <Button style={{ marginRight: '15px' }} onClick={onClose}>
          Close
        </Button>
        <Button onClick={handleSubmit}>Save changes</Button>
      </ModalFooter>
    </Modal>
  );
};

export default CreateDashboard;
