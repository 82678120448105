import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import styles from './styles.scss';

const isValidHex = (hex: string): boolean => {
  if (hex.substring(0, 1) === '#') hex = hex.substring(1);

  switch (hex.length) {
    case 3:
      return /^[0-9A-F]{3}$/i.test(hex);
    case 6:
      return /^[0-9A-F]{6}$/i.test(hex);
    case 8:
      return /^[0-9A-F]{8}$/i.test(hex);
    default:
      return false;
  }
};

const defaultColors = [
  '#FF6900',
  '#FCB900',
  '#7BDCB5',
  '#00D084',
  '#8ED1FC',
  '#0693E3',
  '#ABB8C3',
  '#EB144C',
  '#F78DA7',
  '#9900EF',
];

interface IProps {
  currentColor?: string;
  colors?: Array<string>;
  onChange?: ({ hex }: { hex: string }) => void;
}

const ColorPicker: React.FC<IProps> = ({ colors = defaultColors, currentColor = '86c682', onChange }: IProps) => {
  const [hex, setHex] = useState<string>(currentColor);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    onChange && onChange({ hex });
  }, [hex]);

  const handleColor = (hex: string): void => {
    if (!isValidHex(hex)) {
      setError(true);
      return;
    }

    setError(false);
    setHex(hex.replace('#', ''));
  };

  return (
    <div className={styles.card}>
      <div className={styles.cardBody}>
        {colors.map((color: string, idx: number) => (
          <div
            onClick={() => handleColor(color)}
            title={color}
            style={{ background: color }}
            className={styles.cardBodySwatch}
            key={`swatch-${color}-${idx}`}
          />
        ))}
        <div className={styles.cardBodyHash}>#</div>
        <input
          onChange={event => handleColor(event.target.value)}
          placeholder={hex}
          className={classNames(styles.cardBodyInput, { [styles.cardBodyInputError]: error })}
        />
      </div>
    </div>
  );
};

export default ColorPicker;
