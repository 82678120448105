import React from 'react';
import classNames from 'classnames';
import Header from '~/components/Header';
import Navigation from '~/components/Navigation';
import styles from './styles.scss';

interface IProps {
  children: React.ReactNode;
  center?: boolean;
  containerClass?: string;
}

const AppContent: React.FC<IProps> = ({ children, center = true, containerClass }: IProps) => {
  return (
    <>
      <Header />
      <div className={styles.wrapper}>
        <Navigation />
        <main className={classNames(styles.container, { [styles.containerCenter]: center }, containerClass)}>
          {children}
        </main>
      </div>
    </>
  );
};

export default AppContent;
