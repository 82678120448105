import React from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { HomeIcon, DashboardIcon, ReportIcon, FileshareIcon, RightArrowIcon, LeftArrowIcon, AdminIcon, ToolIcon } from '../Icons';
import { setCollapse } from './actions';
import { IRootState } from '~/store';
import { Routes } from '~/routes/Routes';
import styles from './styles.scss';
import { Roles } from '~/models/IUser';
import { Auth } from '~/lib/auth-lib';

interface IPages {
  name: string;
  label: string;
  href: Routes;
  icon: string;
  visible: boolean;
}

interface IStoreProps {
  isCollapsed: boolean;
}

interface IDispatchProps {
  setCollapse: (value: boolean) => void;
}

type IProps = IStoreProps & IDispatchProps;

const Navigation = ({ isCollapsed, setCollapse }: IProps) => {
  const currentRole: Roles = Auth.getUser().role;

  const pages: IPages[] = [
    { name: 'home', label: 'Home', href: Routes.APP, icon: HomeIcon, visible: true },
    {
      name: 'admin',
      label: 'Admin',
      href: Routes.ADMIN,
      icon: AdminIcon,
      visible: currentRole == Roles.ADMIN || currentRole == Roles.SUPER_ADMIN,
    },
    {
      name: 'dashboards',
      label: 'Dashboards',
      href: Routes.DASHBOARDING,
      icon: DashboardIcon,
      visible: true,
    },
    {
      name: 'reports',
      label: 'Reports',
      href: Routes.REPORTS,
      icon: ReportIcon,
      visible: true,
    },
    {
      name: 'file_share',
      label: 'File Share',
      href: Routes.FILESHARE,
      icon: FileshareIcon,
      visible: true,
    },
    {
      name: 'tools',
      label: 'Tools',
      href: Routes.TOOLS,
      icon: ToolIcon,
      visible: true,
    },
  ];

  const onCollapse = () => {
    setCollapse(!isCollapsed);
  };

  const renderIcons = () => {
    return pages.map(({ label, name, href, icon, visible }, idx: number) => {
      if (visible) {
        return (
          <NavLink
            exact
            key={idx + name}
            to={href}
            title={label}
            className={classNames(styles.navNavigatorLink, { [styles.navNavigatorLinkCenter]: isCollapsed })}
            activeClassName={styles.navNavigatorLinkActive}
          >
            <img className={styles.navNavigatorLinkIcon} src={icon} />
            <span
              className={classNames(styles.navNavigatorLinkName, {
                [styles.navNavigatorLinkNameHidden]: isCollapsed,
              })}
            >
              {label}
            </span>
          </NavLink>
        );
      }
    });
  };

  return (
    <nav className={classNames(styles.nav, { [styles.navCollapsed]: isCollapsed })}>
      <div className={styles.navNavigator}>
        {renderIcons()}
        <div className={styles.navNavigatorDivider} />
      </div>
      <div
        className={classNames(styles.navFooter, {
          [styles.navFooterCenter]: isCollapsed,
        })}
      >
        <button className={classNames(styles.navFooterCollapse)} onClick={onCollapse}>
          <img src={isCollapsed ? RightArrowIcon : LeftArrowIcon} />
        </button>
      </div>
    </nav>
  );
};

const mapStateToProps = ({ navigation }: IRootState) => ({
  isCollapsed: navigation.isCollapsed,
});

export default connect(mapStateToProps, { setCollapse })(Navigation);
