import React, { useState } from 'react';
import { connect } from 'react-redux';
import AuthContent from '~/components/AuthContent';
import Input from '~/components/Input';
import Button from '~/components/Button';
import { IRootState } from '~/store';
import { login } from '../actions/login-actions';
import { Routes } from '~/routes/Routes';
import { IRequestError } from '~/models/IRequest';

interface IStoreProps {
  login: (email: string, password: string) => void;
  isLoading: boolean;
  errors: Array<IRequestError>;
}

const LoginScreen = ({ login, isLoading, errors }: IStoreProps) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const onSubmit = async (event: React.FormEvent<HTMLInputElement>) => {
    if (isLoading) return;
    event.preventDefault();

    return login(email, password);
  };

  return (
    <AuthContent title="Access your account">
      <form>
        <Input
          onChange={({ value }) => setEmail(value)}
          type="email"
          tabIndex={1}
          placeholder="name@work-email.com"
          leftLabel="Email"
          errors={errors}
          name="email"
        />
        <Input
          onChange={({ value }) => setPassword(value)}
          type="password"
          placeholder="*****"
          leftLabel="Password"
          errors={errors}
          tabIndex={2}
          rightLabel="Forgot your password?"
          href={Routes.FORGOT_PASSWORD}
          name="password"
        />
        <Button onClick={onSubmit} isLoading={isLoading}>
          Log In
        </Button>
      </form>
    </AuthContent>
  );
};

const mapStateToProps = ({ login }: IRootState) => ({
  isLoading: login.isLoading,
  errors: login.errors,
});

export default connect(mapStateToProps, { login })(LoginScreen);
