import React from 'react';
import classNames from 'classnames';
import styles from '../styles.scss';

interface IProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  center?: boolean;
}

const Th: React.FC<IProps> = ({ children, style, className, center = false }: IProps) => {
  return (
    <>
      <th className={classNames(styles.tableTh, className, { [styles.tableThCenter]: center })} style={style}>
        {children}
      </th>
    </>
  );
};

export default Th;
