// MeMo logos
export { default as LogoDark } from '../../assets/logo_black.svg';
export { default as LogoLight } from '../../assets/logo_white.svg';
export { default as LogoLightMini } from '../../assets/logo_mini_white.svg';
export { default as LogoDarkMini } from '../../assets/logo_mini_black.svg';

// General assets
export { default as LoadingIcon } from '../../assets/loading.svg';
export { default as RegisterAccountIcon } from '../../assets/register_account.svg';

// Header icons
export { default as LogoutIcon } from '../../assets/header/logout.svg';
export { default as NotificationIcon } from '../../assets/header/notification.svg';
export { default as SettingsIcon } from '../../assets/header/settings.svg';

// Navigation icons
export { default as DashboardIcon } from '../../assets/nav/dashboard.svg';
export { default as FileshareIcon } from '../../assets/nav/fileshare.svg';
export { default as FlowIcon } from '../../assets/nav/flow.svg';
export { default as HomeIcon } from '../../assets/nav/home.svg';
export { default as ReportIcon } from '../../assets/nav/report.svg';
export { default as THXIcon } from '../../assets/nav/thx.svg';
export { default as ToolIcon } from '../../assets/nav/tool.svg';
export { default as AdminIcon } from '../../assets/nav/admin.svg';

export { default as LeftArrowIcon } from '../../assets/nav/left-arrow.svg';
export { default as RightArrowIcon } from '../../assets/nav/right-arrow.svg';

// Toast icons
export { default as ErrorIcon } from '../../assets/toast/error.svg';
export { default as InfoIcon } from '../../assets/toast/info.svg';
export { default as SuccessIcon } from '../../assets/toast/success.svg';
export { default as WarningIcon } from '../../assets/toast/warning.svg';
