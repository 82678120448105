import React from 'react';
import classNames from 'classnames';
import styles from './styles.scss';

interface IProps {
  totalPages: number;
  perPage: number;
  currentPage: number;
  offset?: number;
  onChangePage: ({ newPage }: { newPage: number }) => void;
  style?: React.CSSProperties;
}

const Pagination: React.FC<IProps> = ({
  onChangePage,
  totalPages,
  offset = 3,
  perPage,
  currentPage,
  style,
}: IProps) => {
  const pageArray = [];

  for (let i = 0; i <= totalPages; i++) {
    const isLastPage = totalPages === i;
    const isFirstPage = i === 0;
    if (i < currentPage - offset && !isFirstPage && !isLastPage) {
      if (i === 1) {
        pageArray.push({ label: '...' });
      }
      continue;
    }
    if (i > currentPage + offset && !isFirstPage && !isLastPage) {
      if (i + 1 === totalPages) {
        pageArray.push({ label: '...' });
      }
      continue;
    }
    pageArray.push({ page: i, label: i + 1 });
  }

  const handleChangePage = (newPage: number): void => {
    onChangePage && onChangePage({ newPage });
  };

  return (
    <div className={styles.pagination} style={style}>
      {currentPage > 1 && (
        <button className={styles.paginationBack} onClick={() => handleChangePage(0)}>
          {'<<'}
        </button>
      )}
      {currentPage > 0 && (
        <button className={styles.paginationBack} onClick={() => handleChangePage(currentPage - 1)}>
          {'<'}
        </button>
      )}

      {pageArray.map((page: { page: number; label: number }, idx: number) => (
        <button
          className={classNames(styles.paginationPage, {
            [styles.paginationPageActive]: currentPage == page.page,
          })}
          key={`pagination-${page.page}-${page.label}-${idx}`}
          data-page={page.label}
          disabled={page.page === undefined}
          onClick={() => handleChangePage(page.page)}
        >
          {page.label}
        </button>
      ))}

      {currentPage < totalPages && (
        <button className={styles.paginationNext} onClick={() => handleChangePage(currentPage + 1)}>
          {'>'}
        </button>
      )}
      {currentPage + 1 < totalPages && (
        <button className={styles.paginationNext} onClick={() => handleChangePage(totalPages)}>
          {'>>'}
        </button>
      )}
    </div>
  );
};

export default Pagination;
