import React from 'react';
import classNames from 'classnames';
import styles from '../styles.scss';

interface IProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}

const Tbody: React.FC<IProps> = ({ children, style, className }: IProps) => {
  return (
    <tbody className={classNames(styles.tableTbody, className)} style={style}>
      {children}
    </tbody>
  );
};

export default Tbody;
