import React from 'react';
import classNames from 'classnames';
import styles from '../styles.scss';

interface IProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  border?: boolean;
}

const CardBody = ({ children, style, className, border = true }: IProps) => {
  const newStyles = {
    ...style,
  };

  return (
    <div className={classNames(styles.cardBody, className, { [styles.cardBodyBorder]: border })} style={newStyles}>
      {children}
    </div>
  );
};

export default CardBody;
