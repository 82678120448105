import React from 'react';
import styles from './styles.scss';
import classNames from 'classnames';

/**
 * @TODO add more props to this to modify size instantly. (sm, lg etc.)
 */

interface IProps {
  name: string;
  iconStyle?: React.CSSProperties;
  initialsStyle?: React.CSSProperties;
}

const ProfileIcon: React.FC<IProps> = ({ name, iconStyle, initialsStyle }: IProps) => {
  return (
    <div className={classNames(styles.icon)} style={iconStyle}>
      <div className={classNames(styles.iconInitials)} style={initialsStyle}>
        {name
          .split(' ')
          .map((name, index, array) => (index === 0 || index + 1 === array.length ? name[0] : null))
          .join('')
          .toUpperCase()}
      </div>
    </div>
  );
};

export default ProfileIcon;
