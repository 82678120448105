import axios, { AxiosError } from 'axios';

const Axios = axios.create({
  baseURL: window.location.toString().includes('staging')
    ? 'https://api.gyarados.dev/api'
    : 'http://localhost:3000/api',
});

Axios.interceptors.response.use(null, (err: AxiosError) => {
  return Promise.reject(err);
});

export default Axios;
