import { IRequest } from './IRequest';

export interface IDashboards extends IRequest {
  data: Array<IDashboard>;
}

export interface IDashboard {
  _id?: string;
  workspaceColor: string;
  workspaceName?: string;
  workspaceId?: string;
  workspaceIcon?: string;
  id: string;
  allowedUsers?: string[];
  type: DashboardType;
  subType: string;
  embedUrl: string;
  createdAt?: Date;
}

export enum DashboardType {
  DEFAULT = 'Default',
  BRANDTRACKER = 'BrandTracker',
  CAMPAIGNTRACKER = 'CampaignTracker',
  ATTRIBUTION = 'Attribution',
  MARKETINGSCOREBOARD = 'MarketingScoreboard',
  PRETEST = 'Pretest',
  BENCHMARK = 'Benchmark',
  INSIGHTS = 'Insights',
  THX = 'THX.',
}
