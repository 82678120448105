import React from 'react';
import styles from '../styles.scss';

interface IProps {
  title: string;
  children: React.ReactNode;
}

const Tab: React.FC<IProps> = ({ children }: IProps) => {
  return <div className={styles.tabsTab}>{children}</div>;
};

export default Tab;
