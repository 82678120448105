import { useEffect } from 'react';

const useAsyncEffect = (
  effect: (isMounted: () => boolean) => unknown | Promise<unknown>,
  destroy?: (result?: unknown) => void,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputs?: any[],
): void => {
  useEffect(() => {
    let result: unknown;
    let mounted = true;
    const promise = effect(() => {
      return mounted;
    });

    Promise.resolve(promise).then(value => {
      result = value;
    });

    return () => {
      mounted = false;

      if (destroy) {
        console.log('there is a destroy');
        destroy(result);
      }
    };
  }, inputs);
};

export { useAsyncEffect };
