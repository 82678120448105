import { Dispatch } from 'redux';

import { history } from '~/store';
import { REGISTER_REQUEST, REGISTER_SUCCESS, REGISTER_FAILURE } from '../constants';
import { handleRegistration } from '~/services/auth-service';
import { Routes } from '~/routes/Routes';
import { IRequestError } from '~/models/IRequest';

export type Action = RequestAction | SuccessAction | FailureAction;

type RequestAction = { type: REGISTER_REQUEST; payload: boolean };
type SuccessAction = { type: REGISTER_SUCCESS; payload: IRegisterUser };
type FailureAction = {
  type: REGISTER_FAILURE;
  payload: Array<IRequestError>;
};

export interface IRegisterUser {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  passwordConfirm: string;
}

export const register = (user: IRegisterUser) => {
  return (dispatch: Dispatch) => {
    const request = (): RequestAction => ({
      type: REGISTER_REQUEST,
      payload: true,
    });

    const success = (user: IRegisterUser): SuccessAction => ({
      type: REGISTER_SUCCESS,
      payload: user,
    });

    const failure = (isFailure: Array<IRequestError>): FailureAction => ({
      type: REGISTER_FAILURE,
      payload: isFailure,
    });

    dispatch(request());

    handleRegistration(user).then(response => {
      if (response.success) {
        dispatch(success(user));
        history.push(Routes.VERIFY);
      } else {
        dispatch(failure(response.errors));
      }
    });
  };
};
