import AdminScreen from './containers/AdminScreen';
import AdminUsersScreen from './containers/AdminUsersScreen';
import AdminWorkspacesScreen from './containers/AdminWorkspacesScreen';
import AdminWorkspaceScreen from './containers/AdminWorkspaceScreen';

export { AdminScreen, AdminUsersScreen, AdminWorkspacesScreen, AdminWorkspaceScreen };

export default {
  AdminScreen,
  AdminUsersScreen,
  AdminWorkspacesScreen,
  AdminWorkspaceScreen,
};
