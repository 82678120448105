import React, { useState } from 'react';
import Button from '~/components/Button';
import Input from '~/components/Input';
import { Modal, ModalBody, ModalFooter, ModalTitle } from '~/components/Modal';
import { useToast, ToastVariants } from '~/components/Toast';
import { createWorkspace } from '~/services/workspaces-service';

interface IProps {
  onClose: () => void;
}

const CreateWorkspace: React.FC<IProps> = ({ onClose }: IProps) => {
  const { addToast } = useToast();
  const [userEmail, setUserEmail] = useState<string>();
  const [workspaceName, setWorkspaceName] = useState<string>();

  const handleSubmit = async () => {
    console.log('submit');

    createWorkspace(userEmail, workspaceName)
      .then(res => {
        if (res.success) {
          onClose && onClose();
          return addToast('Created a new Workspace.');
        }

        addToast('Failed to create the Workspace.', ToastVariants.DANGER);
      })
      .catch(err => {
        console.error(err);
      });
  };

  return (
    <Modal onClose={onClose}>
      <ModalTitle>Create New Workspace</ModalTitle>
      <ModalBody>
        <Input
          style={{ width: '100%' }}
          name="userEmail"
          placeholder="Email of workspace owner..."
          onChange={({ value }) => setUserEmail(value)}
        />
        <Input
          style={{ width: '100%' }}
          name="workspaceName"
          placeholder="Workspace name..."
          onChange={({ value }) => setWorkspaceName(value)}
        />
      </ModalBody>
      <ModalFooter style={{ justifyContent: 'flex-end' }}>
        <Button style={{ marginRight: '15px' }} onClick={onClose}>
          Close
        </Button>
        <Button onClick={handleSubmit}>Save changes</Button>
      </ModalFooter>
    </Modal>
  );
};

export default CreateWorkspace;
