import React, { useState, useEffect } from 'react';
import AppContent from '~/components/AppContent';
import styles from '../styles.scss';
import { Heading, HeadingBody, HeadingTitle } from '~/components/Heading';
import { IWorkspace } from '~/models/IWorkspace';
import { Table, Tbody, Td, Th, Thead, Tr } from '~/components/Table';
import { getWorkspaces } from '~/services/workspaces-service';
import { convertDate } from '~/utils/date';
import CreateWorkspace from '../components/CreateWorkspace';
import Button from '~/components/Button';
import Input from '~/components/Input';
import { history } from '~/store';
import { Routes } from '~/routes/Routes';
import Select from '~/components/Select';

const columns = [
  { label: '#', column: 'id' },
  { label: 'Company', column: 'company' },
  { label: 'Status', column: 'status' },
  { label: 'Dashboards', column: 'dashboards' },
  { label: 'Users', column: 'users' },
  { label: 'Color', column: 'color' },
  { label: 'Created', column: 'created' },
];

const selectKeys = ['Company', 'Status'];

const AdminWorkspacesScreen: React.FC = () => {
  const [workspaces, setWorkspaces] = useState<Array<IWorkspace> | undefined>(undefined);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [createWorkspaceModal, setCreateWorkspaceModal] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchKey, setSearchKey] = useState<string>();
  const [search, setSearch] = useState<number>(0);

  useEffect(() => {
    console.log('fetching the workspaces');

    getWorkspaces(25, 0, searchKey, searchValue)
      .then(res => {
        if (res.success) {
          setLoading(false);
          setWorkspaces(res.workspaces);
        }
      })
      .catch(err => {
        setError(true);
        setLoading(false);
        console.error(err);
      });
  }, [createWorkspaceModal, search]);

  const renderWorkspaces = () => {
    if (isLoading) {
      return <span className={styles.notice}>Loading...</span>;
    }

    if (error) {
      return <span className={styles.notice}>Error occurred loading &apos;DEEP&apos; services.</span>;
    }

    if (workspaces == undefined || workspaces.length <= 0) {
      return <span className={styles.notice}>No workspaces found.</span>;
    }

    return (
      <Table>
        <Thead>
          <Tr>
            {columns.map((col, idx: number) => {
              return (
                <Th data-label={col} key={`col-${col.column}-${idx}`}>
                  {col.label}
                </Th>
              );
            })}
          </Tr>
        </Thead>
        <Tbody>
          {workspaces &&
            workspaces.map((workspace: IWorkspace, idx) => {
              return (
                <Tr
                  onClick={() => {
                    history.push(Routes.ADMIN_WORKSPACES + '/' + workspace._id);
                  }}
                  data-label={workspace.name}
                  key={`workspace-${workspace.name}-${idx}`}
                >
                  <Td>{idx + 1}</Td>
                  <Td>{workspace.company}</Td>
                  <Td>{workspace.status}</Td>
                  <Td>{workspace.dashboards.length <= 0 ? '--' : workspace.dashboards.length}</Td>
                  <Td>{workspace.users.length + '/' + workspace.maxUsers}</Td>
                  <Td style={{ color: `#${workspace.color}`, fontWeight: 500 }}>#{workspace.color}</Td>
                  <Td>{convertDate(workspace.createdAt)}</Td>
                </Tr>
              );
            })}
        </Tbody>
      </Table>
    );
  };

  return (
    <AppContent center={false}>
      {createWorkspaceModal && <CreateWorkspace onClose={() => setCreateWorkspaceModal(!createWorkspaceModal)} />}
      <div className={styles.admin}>
        <Heading style={{ width: '450px' }}>
          <HeadingBody>
            <HeadingTitle>Workspace Management</HeadingTitle>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum malesuada erat et pharetra accumsan.
            Phasellus semper nibh non ultricies placerat. Mauris in mi vel nibh viverra elementum ut at augue.
          </HeadingBody>
        </Heading>

        <div className={styles.adminBody}>
          <div className={styles.adminBodyActions}>
            <Button onClick={() => setCreateWorkspaceModal(!createWorkspaceModal)}>Create Workspace</Button>
            <Select
              style={{ marginRight: '15px' }}
              options={selectKeys}
              onChange={({ selected }) => setSearchKey(selected.toLowerCase())}
            />
            <Input
              onChange={({ value }) => {
                setSearchValue(value);
              }}
              onEnter={() => setSearch(value => value + 1)}
              placeholder="Search for value..."
              name="search"
            />
          </div>
          {renderWorkspaces()}
        </div>
      </div>
    </AppContent>
  );
};

export default AdminWorkspacesScreen;
