import { combineReducers } from 'redux';
import { History } from 'history';
import { connectRouter } from 'connected-react-router';

import { IRootState } from '.';
import { navigationReducer } from '../components/Navigation/reducers';
import { loginReducer } from '~/modules/Auth/reducers/login-reducers';
import { registerReducer } from '~/modules/Auth/reducers/register-reducers';
import { verifyReducer } from '~/modules/Auth/reducers/verify-reducers';

const rootReducer = (history: History) =>
  combineReducers<IRootState>({
    router: connectRouter(history),
    navigation: navigationReducer,
    login: loginReducer,
    register: registerReducer,
    verify: verifyReducer,
  });

export default rootReducer;
