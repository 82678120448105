export const convertDate = (date: Date): string => {
  const newDate = new Date(date);

  const stringDate: string =
    ('0' + newDate.getDate()).slice(-2) +
    '-' +
    ('0' + (newDate.getMonth() + 1)).slice(-2) +
    '-' +
    newDate.getFullYear();

  return `${stringDate}, ${newDate.getHours()}:${newDate.getMinutes() < 10 ? '0' : ''}${newDate.getMinutes()}`;
};

export const THIS_YEAR: number = new Date().getFullYear();

export const THIS_MONTH: number = new Date().getMonth();

export const TODAY: Date = new Date();

export const getMonthFirstDay = (date: Date): number => {
  return new Date(date.getFullYear(), date.getMonth(), 1).getDay();
};

export const getMonthDays = (date: Date): number => {
  const monthsWith30Days: Array<number> = [3, 5, 9, 10];
  const leapYear: boolean = date.getFullYear() % 4 === 0;

  return date.getMonth() === 1 ? (leapYear ? 29 : 28) : monthsWith30Days.includes(date.getMonth()) ? 30 : 31;
};

export enum CALENDAR_MONTHS {
  JANUARY = 'January',
  FEBRUARY = 'February',
  MARCH = 'March',
  APRIL = 'April',
  MAY = 'May',
  JUNE = 'June',
  JULY = 'July',
  AUGUST = 'August',
  SEPTEMBER = 'September',
  OCTOBER = 'October',
  NOVEMBER = 'November',
  DECEMBER = 'December',
}

/**
 * @TODO Change these ENUMS to a dynamic {Array}.
 */
export enum WEEK_DAYS_MONDAY {
  MONDAY = 'Mo',
  TUESDAY = 'Tu',
  WEDNESDAY = 'We',
  THURSDAY = 'Th',
  FRIDAY = 'Fr',
  SATURDAY = 'Sa',
  SUNDAY = 'Su',
}

export enum WEEK_DAYS_SUNDAY {
  SUNDAY = 'Su',
  MONDAY = 'Mo',
  TUESDAY = 'Tu',
  WEDNESDAY = 'We',
  THURSDAY = 'Th',
  FRIDAY = 'Fr',
  SATURDAY = 'Sa',
}

export const readableDate = (date: Date): string => {
  const newDate = new Date(date);

  const stringDate: string =
    ('0' + newDate.getDate()).slice(-2) +
    '-' +
    ('0' + (newDate.getMonth() + 1)).slice(-2) +
    '-' +
    newDate.getFullYear();

  return `${stringDate}, ${newDate.getHours()}:${newDate.getMinutes() < 10 ? '0' : ''}${newDate.getMinutes()}`;
};
