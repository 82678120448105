import { AxiosError, AxiosResponse } from 'axios';
import axios from '~/lib/axios-client';

import { setLocalStorage, removeLocalStorage } from '~/services/localstorage-service';
import { IRegisterUser } from '~/modules/Auth/actions/register-actions';
import { IRequest } from '~/models/IRequest';

export const USER_STORAGE = 'current_user';
export const TOKEN_STORAGE = 'current_token';

export const handleLogin = async (email: string, password: string) => {
  return await axios
    .post('/v1/auth/login', {
      email,
      password,
    })
    .then((res: AxiosResponse) => {
      const data: IRequest = res.data;

      if (data.success) {
        setLocalStorage(USER_STORAGE, data.user);
        setLocalStorage(TOKEN_STORAGE, data.token);

        return data;
      }
    })
    .catch((err: AxiosError) => {
      if (err.response) {
        return err.response.data;
      }
    });
};

export const handleLogout = () => {
  removeLocalStorage(USER_STORAGE);
  removeLocalStorage(TOKEN_STORAGE);
};

export const handleRegistration = async (user: IRegisterUser) => {
  return await axios
    .post('/v1/auth/register', {
      ...user,
    })
    .then((res: AxiosResponse) => {
      const data: IRequest = res.data;

      if (data.success) {
        return data;
      }
    })
    .catch((err: AxiosError) => {
      if (err.response) {
        return err.response.data;
      }
    });
};

export const handleVerificationRequest = async (email: string) => {
  return await axios
    .post('/v1/auth/req/verify', {
      email,
    })
    .then((res: AxiosResponse) => {
      const data: IRequest = res.data;

      if (data.success) {
        return data;
      }
    })
    .catch((err: AxiosError) => {
      if (err.response) {
        return err.response.data;
      }
    });
};

export const handleVerification = async (email: string, token: number) => {
  return await axios
    .post('/v1/auth/verify', {
      email,
      token,
    })
    .then((res: AxiosResponse) => {
      const data: IRequest = res.data;

      if (data.success) {
        return data;
      }
    })
    .catch((err: AxiosError) => {
      if (err.response) {
        return err.response.data;
      }
    });
};
