import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import AppContent from '~/components/AppContent';
import styles from '../styles.scss';
import { downloadBlob, formatBytes, truncateLongName } from '~/utils/files';
import { getUserFiles, downloadFile, deleteFile } from '~/services/fileshare-service';
import { IFile } from '~/models/IFileshare';
import DragAndDrop from '~/components/DragAndDrop';
import Button from '~/components/Button';
import { Heading, HeadingBody, HeadingTitle } from '../../../components/Heading';
import { convertDate } from '~/utils/date';
import axios from '~/lib/axios-client';
import { Modal, ModalBody, ModalFooter, ModalTitle } from '~/components/Modal';
import { Auth } from '~/lib/auth-lib';
import { useSnackbar } from '~/hooks/useSnackbar';
import Snackbar from '~/components/Snackbar';
import { Table, Tbody, Td, Th, Thead, Tr } from '~/components/Table';

const FileShareScreen: React.FC = () => {
  const [files, setFiles] = useState<Array<IFile>>([]);
  const [uploadFiles, setUploadFiles] = useState<Array<File>>([]);
  const [selectedFile, setSelectedFile] = useState<IFile>();
  const [removeModal, setRemoveModal] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const { type, isActive, message, openSnackBar } = useSnackbar();

  useEffect(() => {
    getUserFiles()
      .then(response => {
        const files: Array<IFile> = response.data;
        if (response.success) {
          console.log(files);
          setFiles(files);
        }
      })
      .catch(err => {
        console.error(err);
      });
  }, [uploadFiles, removeModal, uploadFiles]);

  useEffect(() => {
    if (error) {
      setError(false);
      return openSnackBar(`Failed to upload the file`, 'red');
    }

    if (uploadProgress == 100) {
      setUploadProgress(0);
    }

    if (uploadProgress > 0) {
      openSnackBar(`Uploading file - ${uploadProgress}%`);
    }
  }, [uploadProgress, error]);

  const upload = async (files: Array<File>) => {
    const formData = new FormData();

    files.forEach(file => {
      formData.append('file', file);
    });

    const data = formData;

    const headers = {
      headers: {
        Authorization: `Bearer ${Auth.getToken()}`,
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent: ProgressEvent) => {
        const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setUploadProgress(progress);
      },
    };

    await axios
      .post(`/v1/fileshare/upload`, data, headers)
      .then(response => {
        if (response.data.success) {
          console.log(response);
          setUploadFiles(files);
          return;
        }

        console.log('1 failed to upload file');
        setError(true);
      })
      .catch(err => {
        console.error(err);
        console.log('2 failed to upload file');
        setError(true);
      });
  };

  const handleRemove = async () => {
    console.log('remove -> ' + selectedFile.id);

    deleteFile(selectedFile.id)
      .then(res => {
        console.log(res);
        if (res.success) {
          setRemoveModal(!removeModal);
        }
      })
      .catch(err => {
        console.error(err);
      });
  };

  const download = (fileId: string, filename: string) => {
    downloadFile(fileId)
      .then(response => {
        if (response.status != 200) {
          console.error('something went wrong');
          return;
        }

        downloadBlob(response.data, filename);
      })
      .catch(err => {
        console.error(err);
      });
  };

  return (
    <AppContent>
      {removeModal && (
        <Modal>
          <ModalTitle>Remove file?</ModalTitle>
          <ModalBody>
            Are you sure you want to <span style={{ fontStyle: 'italic' }}>remove </span>
            <span style={{ fontWeight: 600 }}>{selectedFile && selectedFile.originalname}</span> from your Fileshare?
            You cannot revert this action.
          </ModalBody>
          <ModalFooter style={{ justifyContent: 'flex-end' }}>
            <Button style={{ marginRight: '15px' }} onClick={() => setRemoveModal(!removeModal)}>
              Close
            </Button>
            <Button onClick={handleRemove}>Remove</Button>
          </ModalFooter>
        </Modal>
      )}
      <DragAndDrop
        onEnter={({ canDrop }) => console.log(`the file can be droped ${canDrop}`)}
        onLeave={() => console.log('the file is out of sight')}
        onFileDrop={files => upload(files)}
      >
        <div className={classNames(styles.fileshare, { [styles.fileshareCenter]: files.length <= 0 })}>
          {files.length <= 0 && (
            <Heading style={{ width: '500px' }}>
              <HeadingBody>
                <HeadingTitle>File Share</HeadingTitle>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum malesuada erat et pharetra accumsan.
                Phasellus semper nibh non ultricies placerat. Mauris in mi vel nibh viverra elementum ut at augue.{' '}
              </HeadingBody>
            </Heading>
          )}
          {files.length > 0 && (
            <Table>
              <Thead>
                <Tr>
                  <Th style={{ width: '33.3%' }}>Name</Th>
                  <Th>Modified</Th>
                  <Th>Type</Th>
                  <Th>Size</Th>
                  <Th center={true}>Download</Th>
                  <Th center={true}>Remove</Th>
                </Tr>
              </Thead>
              <Tbody>
                {files &&
                  files.map((file: IFile, idx: number) => {
                    return (
                      <Tr key={`file-${idx}`}>
                        <Td bold={true}>{truncateLongName(file.originalname, 70)}</Td>
                        <Td>{file.updatedAt ? convertDate(file.updatedAt) : '--'}</Td>
                        <Td>{file.mimetype ? file.mimetype : '--'}</Td>
                        <Td>{formatBytes(file.size)}</Td>
                        <Td
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            download(file.id, file.originalname);
                          }}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />
                            <polyline points="7 11 12 16 17 11" />
                            <line x1="12" y1="4" x2="12" y2="16" />
                          </svg>
                        </Td>
                        <Td
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            setSelectedFile(file);
                            setRemoveModal(true);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className={styles.red}
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                            <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                            <line x1="9" y1="14" x2="15" y2="14" />
                          </svg>
                        </Td>
                      </Tr>
                    );
                  })}
              </Tbody>
            </Table>
          )}
          <div className={styles.fileshareDrop}>
            <div className={styles.fileshareDropIcon}>
              <svg
                id="fileshare_icon"
                data-name="fileshare_icon"
                width="89.503"
                height="67.127"
                viewBox="0 0 89.503 67.127"
              >
                <path
                  id="icon"
                  data-name="icon"
                  d="M72.218,23.011a27.9,27.9,0,0,0-54.938,0,22.35,22.35,0,0,0,5.1,44.117H67.127a22.349,22.349,0,0,0,5.09-44.117ZM50.345,39.158V55.939H39.158V39.158H27.97L44.751,22.376,61.533,39.158Z"
                />
              </svg>
            </div>
            <div className={styles.fileshareDropContent}>
              <div className={styles.fileshareDropContentTitle}>Drop files that you want to share.</div>
              <div className={classNames(styles.fileshareDropContentTitle, styles.fileshareDropContentTitleSub)}>
                Your files will be send securely accross the web.
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'relative',
                }}
              >
                <Snackbar type={type} percentage={uploadProgress} active={isActive} message={message} />
              </div>
            </div>
          </div>
        </div>
      </DragAndDrop>
    </AppContent>
  );
};

export default FileShareScreen;
