import { AxiosError, AxiosResponse } from 'axios';
import { Auth } from '~/lib/auth-lib';
import axios from '~/lib/axios-client';
import { IRequest } from '~/models/IRequest';
import { TOKEN_STORAGE, USER_STORAGE } from './auth-service';
import { setLocalStorage } from './localstorage-service';

export const getUser = async () => {
  return await axios
    .get('/v1/user', {
      headers: {
        Authorization: `Bearer ${Auth.getToken()}`,
      },
    })
    .then((res: AxiosResponse) => {
      const data: IRequest = res.data;

      if (data.success) {
        return data;
      }
    })
    .catch((err: AxiosError) => {
      if (err.response) {
        return err.response.data;
      }
    });
};

export const updateUser = async (password: string, firstName: string, lastName: string) => {
  const body = {
    password: password,
    firstName: firstName,
    lastName: lastName,
  };

  return await axios
    .patch('/v1/user/settings', body, {
      headers: {
        Authorization: `Bearer ${Auth.getToken()}`,
      },
    })
    .then((res: AxiosResponse) => {
      const data: IRequest = res.data;

      if (data.success) {
        setLocalStorage(USER_STORAGE, data.user);
        setLocalStorage(TOKEN_STORAGE, data.token);

        return data;
      }
    })
    .catch((err: AxiosError) => {
      if (err.response) {
        return err.response.data;
      }
    });
};
