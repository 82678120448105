import { useEffect, useState } from 'react';

export type SnackbarTypes = 'gray' | 'red' | 'orange' | 'default';

export const useSnackbar = () => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const [message, setMessage] = useState<string>();
  const [type, setType] = useState<SnackbarTypes>('default');

  useEffect(() => {
    if (isActive === true) {
      // setTimeout(() => {
      // setIsActive(false);
      // }, 5000);
    }
  }, [isActive]);

  const openSnackBar = (msg: string, type?: SnackbarTypes) => {
    setMessage(msg);
    setIsActive(true);
    setType(type);
  };

  return { type, isActive, message, openSnackBar };
};
