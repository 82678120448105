import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import {
  getMonthDays,
  getMonthFirstDay,
  THIS_MONTH,
  THIS_YEAR,
  TODAY,
  WEEK_DAYS_MONDAY,
  WEEK_DAYS_SUNDAY,
  CALENDAR_MONTHS,
} from '../../utils/date';
import styles from './styles.scss';
import { capitalizeFirstLetter } from '../../utils/string';

type WeekStart = 'MONDAY' | 'SUNDAY';

interface IProps {
  weekStart?: WeekStart;
  onSelected?: ({ date }: { date: Date }) => void;
  calendarClassname?: string;
  headerClassname?: string;
  weekClassname?: string;
  onClose?: () => void;
  closeOnClickOutside?: boolean;
  selectedStart?: Date;
  selectedEnd?: Date;
}

const Calendar: React.FC<IProps> = ({
  weekStart = 'SUNDAY',
  onSelected,
  calendarClassname,
  headerClassname,
  weekClassname,
  selectedStart,
  selectedEnd,
}: IProps) => {
  const [date, setDate] = useState(TODAY);
  const [selectedDay, setSelectedDay] = useState(date.getDate());
  const [selectedDate, setSelectedDate] = useState(date);
  const [month, setMonth] = useState(date.getMonth());
  const [year, setYear] = useState(date.getFullYear());

  const startDay = getMonthFirstDay(date);
  const isWeekStartMonday: boolean = weekStart === 'MONDAY';
  const hasHighlight = selectedStart != null && selectedEnd != null;

  useEffect(() => {
    setSelectedDay(date.getDate());
    setMonth(date.getMonth());
    setYear(date.getFullYear());
  }, [date]);

  const onSelectedDay = (day: number): void => {
    const newDate = new Date(year, month, day);
    setDate(newDate);
    setSelectedDate(newDate);

    onSelected && onSelected({ date: newDate });
  };

  const renderMonthAndYear = (): React.ReactElement => {
    const monthByName: string = capitalizeFirstLetter(Object.keys(CALENDAR_MONTHS)[month]);

    return (
      <div className={classNames(styles.calendarHeader, headerClassname)}>
        <div className={styles.calendarHeaderButton} onClick={() => setDate(new Date(year, month - 1, selectedDay))}>
          {'<'}
        </div>
        <span>
          {monthByName} {year}
        </span>
        <div className={styles.calendarHeaderButton} onClick={() => setDate(new Date(year, month + 1, selectedDay))}>
          {'>'}
        </div>
      </div>
    );
  };

  const isHighlighted = (day: number) => {
    if (!hasHighlight) return false;
    const dayDate = new Date(year, month, day);
    return dayDate >= selectedStart && dayDate <= selectedEnd;
  };

  const isSelected = (day: number) => {
    console.log(`is selected?`)
    const t = new Date(year, month, day)
    console.log(t)
    console.log(selectedStart)
    return t == selectedStart;
  };

  const isToday = (day: number) => {
    if (TODAY.getFullYear() != year || TODAY.getMonth() != month) return false;
    return TODAY.getDate() == day;
  };

  const populateCalender = (): React.ReactElement => {
    const fixedStartDay: number = isWeekStartMonday ? startDay - 1 : startDay;
    const fixedDay: number = isWeekStartMonday ? startDay - 2 : startDay - 1;

    return (
      <>
        {Array(getMonthDays(date) + fixedStartDay)
          .fill(null)
          .map((_: any, idx: number) => {
            const day: number = idx - fixedDay;
            return (
              <div
                className={classNames(styles.calendarBodyDay, {
                  [styles.calendarBodyDayToday]: isToday(day),
                  [styles.calendarBodyDaySelected]: !hasHighlight && isSelected(day),
                  [styles.calendarBodyDayHighlighted]: hasHighlight && isHighlighted(day),
                })}
                key={idx}
                onClick={() => onSelectedDay(day)}
              >
                {day > 0 ? day : ''}
              </div>
            );
          })}
      </>
    );
  };

  const renderWeekDays = (): React.ReactElement => {
    return (
      <div className={styles.calendarBody}>
        {Object.values(isWeekStartMonday ? WEEK_DAYS_MONDAY : WEEK_DAYS_SUNDAY).map((day, idx) => (
          <span className={classNames(styles.calendarBodyWeek, weekClassname)} key={idx}>
            {day}
          </span>
        ))}
        {populateCalender()}
      </div>
    );
  };

  return (
    <div className={classNames(styles.calendar, calendarClassname)}>
      {renderMonthAndYear()}
      {renderWeekDays()}
    </div>
  );
};

export default Calendar;
