import React, { useCallback } from 'react';
import styles from '../styles.scss';
import classNames from 'classnames';

interface IProps {
  title: string;
  index: number;
  active: boolean;
  setSelectedTab: (index: number) => void;
}

const TabTitle: React.FC<IProps> = ({ title, active = false, setSelectedTab, index }: IProps) => {
  const onClick = useCallback(() => {
    setSelectedTab(index);
  }, [setSelectedTab, index]);

  return (
    <div
      onClick={onClick}
      className={classNames(styles.tabsContainerTitle, { [styles.tabsContainerTitleActive]: active })}
    >
      <div>{title}</div>
    </div>
  );
};

export default TabTitle;
