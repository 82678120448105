import React from 'react';
import classNames from 'classnames';
import styles from './styles.scss';

interface IProps {
  children: React.ReactNode;
  percentage: number;
  color?: string;
}

const Progressbar: React.FC<IProps> = ({ percentage = 0, color = '86c682', children }: IProps) => {
  return (
    <div className={classNames(styles.progressbar)}>
      <div className={classNames(styles.progressbarFill)} style={{ width: `${percentage}%`, background: `#${color}` }}>
        <span className={styles.progressbarFillPercentage}>{children}</span>
      </div>
    </div>
  );
};

export default Progressbar;
