export enum Routes {
  BASE = '/',
  LOGIN = '/login',
  REGISTER = '/register',
  VERIFY = '/verify',
  FORGOT_PASSWORD = '/forgot_password',
  APP = '/app',
  LOGOUT = '/app/logout',
  USER_SETTINGS = '/app/user/settings',
  DASHBOARDING = '/app/dashboards',
  REPORTS = '/app/reports',
  THX = '/app/thx',
  FLOW = '/app/flow',
  FILESHARE = '/app/fileshare',
  TOOLS = '/app/tools',
  QUOTA = '/app/tools/quota',
  ADMIN = '/app/admin',
  ADMIN_USERS = '/app/admin/users',
  ADMIN_USER = '/app/admin/users',
  ADMIN_WORKSPACES = '/app/admin/workspaces',
}
