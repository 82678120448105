import { setLocalStorage, removeLocalStorage, getLocalStorage } from '~/services/localstorage-service';
import { USER_STORAGE, TOKEN_STORAGE } from '~/services/auth-service';
import { IUser } from '~/models/IUser';

export class Auth {
  static authenticateUser(token: string, data: IUser) {
    setLocalStorage(USER_STORAGE, data);
    setLocalStorage(TOKEN_STORAGE, token);
  }

  static deauthenticateUser(): void {
    removeLocalStorage(TOKEN_STORAGE);
    removeLocalStorage(USER_STORAGE);
  }

  static isUserAuthenticated(): boolean {
    return getLocalStorage(TOKEN_STORAGE);
  }

  static getToken(): string {
    return getLocalStorage(TOKEN_STORAGE);
  }

  static getUser(): IUser {
    return getLocalStorage(USER_STORAGE);
  }

  static getUserFullName(): string {
    const name = `${Auth.getUser().firstName} ${Auth.getUser().lastName}`;
    return name;
  }

  static getUserInitials(): string {
    const name = `${Auth.getUser().firstName} ${Auth.getUser().lastName}`;
    const initials = name
      .split(' ')
      .map((name, index, array) => (index === 0 || index + 1 === array.length ? name[0] : null))
      .join('')
      .toUpperCase();

    return initials;
  }
}
