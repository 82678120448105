import React from 'react';
import classNames from 'classnames';
import styles from '../styles.scss';

interface IProps {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}

const CardHeader = ({ children, style, className }: IProps) => {
  const newStyles: React.CSSProperties = {
    ...style,
    borderTopRightRadius: '8px',
    borderTopLeftRadius: '8px',
  };

  return (
    <div className={classNames(styles.cardHeader, className)} style={newStyles}>
      {children}
    </div>
  );
};

export default CardHeader;
