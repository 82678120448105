import React from 'react';
import classNames from 'classnames';

import styles from './style.module.scss';

type Sizes = 'small' | 'medium' | 'large';

interface IProps {
  className?: string;
  checked: boolean;
  leftLabel?: string;
  rightLabel?: string;
  size?: Sizes;
  onClick: (checked: boolean) => void;
  style?: React.CSSProperties;
}

const Checkbox = ({ className, checked, leftLabel, rightLabel, size = 'medium', onClick, style }: IProps) => {
  const handleClick = () => {
    onClick && onClick(!checked);
  };

  const newClass = classNames(styles.checkbox, { [styles.active]: checked }, className, {
    [styles.small]: size == 'small',
    [styles.medium]: size == 'medium',
    [styles.large]: size == 'large',
  });

  return (
    <div className={styles.wrapper} onClick={handleClick}>
      {leftLabel && <div className={styles.leftLabel}>{leftLabel}</div>}
      <div className={newClass}></div>
      {rightLabel && <div className={styles.rightLabel}>{rightLabel}</div>}
    </div>
  );
};

export default Checkbox;
