import { AxiosError, AxiosResponse } from 'axios';
import { Auth } from '~/lib/auth-lib';
import axios from '~/lib/axios-client';
import { IRequest } from '~/models/IRequest';

export const getUsers = async (limit: number, page: number, key: string, value: string) => {
  // Set the key to empty if no value provided.
  if (value.trim().length <= 0) {
    key = '';
  }

  return await axios
    .get('/v1/users', {
      params: {
        limit,
        page,
        key,
        value,
      },
      headers: {
        Authorization: `Bearer ${Auth.getToken()}`,
      },
    })
    .then((res: AxiosResponse) => {
      const data: IRequest = res.data;

      if (data.success) {
        return data;
      }
    })
    .catch((err: AxiosError) => {
      if (err.response) {
        return err.response.data;
      }
    });
};

export const getUser = async (id: string) => {
  return await axios
    .get(`/v1/users/${id}`, {
      headers: {
        Authorization: `Bearer ${Auth.getToken()}`,
      },
    })
    .then((res: AxiosResponse) => {
      const data: IRequest = res.data;

      if (data.success) {
        return data;
      }
    })
    .catch((err: AxiosError) => {
      if (err.response) {
        return err.response.data;
      }
    });
};

export const deleteUser = async (id: string) => {
  return await axios
    .delete(`/v1/users/${id}`, {
      headers: {
        Authorization: `Bearer ${Auth.getToken()}`,
      },
    })
    .then((res: AxiosResponse) => {
      const data: IRequest = res.data;

      if (data.success) {
        return data;
      }
    })
    .catch((err: AxiosError) => {
      if (err.response) {
        return err.response.data;
      }
    });
};

export const create = async ({
  email,
  firstName,
  lastName,
}: {
  email: string;
  firstName: string;
  lastName: string;
}) => {
  const body = {
    email,
    firstName,
    lastName,
  };

  return await axios
    .post('/v1/users/create', body, {
      headers: {
        Authorization: `Bearer ${Auth.getToken()}`,
      },
    })
    .then((res: AxiosResponse) => {
      const data: IRequest = res.data;

      if (data.success) {
        return data;
      }
    })
    .catch((err: AxiosError) => {
      if (err.response) {
        return err.response.data;
      }
    });
};
