import React, { useState } from 'react';
import Button from '~/components/Button';
import { Modal, ModalBody, ModalFooter, ModalTitle } from '~/components/Modal';
import ProfileIcon from '~/components/ProfileIcon';
import { useToast, ToastVariants } from '~/components/Toast';
import { Auth } from '~/lib/auth-lib';
import { IUser } from '~/models/IUser';
import { deleteUser } from '~/services/users-service';
import styles from '../styles.scss';

interface IProps {
  onClose: () => void;
  user: IUser;
}

const UserLookup: React.FC<IProps> = ({ onClose, user }: IProps) => {
  const [modify, setModify] = useState<boolean>(false);
  const { addToast } = useToast();

  const handleReset = async () => {
    console.log('do reset');
  };

  const handleDelete = async () => {
    console.log('do delete');

    deleteUser(user._id)
      .then(data => {
        if (data.success) {
          console.log(data);
          addToast('Deleted the User');
          return onClose && onClose();
        }

        addToast('Failed to delete the User', ToastVariants.DANGER);
      })
      .catch(err => {
        console.error(err);
      });
  };

  return (
    <Modal onClose={onClose}>
      <ModalTitle>{user.email}&apos;s Profile</ModalTitle>
      <ModalBody>
        <ProfileIcon name={user.firstName + ' ' + user.lastName} />
      </ModalBody>
      <ModalFooter style={{ justifyContent: 'flex-end' }}>
        <Button type="red" className={styles.userButton} style={{ marginRight: '15px' }} onClick={handleDelete}>
          Delete User
        </Button>
        <Button type="orange" className={styles.userButton} style={{ marginRight: '15px' }} onClick={handleReset}>
          Reset User
        </Button>
        <Button className={styles.userButton} onClick={() => setModify(!modify)}>
          {modify ? 'Save' : 'Edit'}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default UserLookup;
