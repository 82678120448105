import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import styles from './styles.scss';
import Input from '../Input';
import Calendar from '../Calendar';

interface IProps {
  onPick?: ({ startDate, endDate }: { startDate: Date; endDate: Date }) => void;
  labelTop?: string;
  labelLeft?: string;
  inputClassName?: string;
  className?: string;
  isRange?: boolean;
  style?: React.CSSProperties;
}

const Datepicker = ({ onPick, labelTop, labelLeft, inputClassName, className, isRange }: IProps) => {
  const [show, setShow] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [isStart, setIsStart] = useState<boolean>(true);

  const calendarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target as Node)) {
        setShow(false);
      }
    };

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleOpen = (focusStart: boolean) => {
    if (focusStart) {
      setIsStart(true);
    } else setIsStart(false);
    setShow(true);
  };

  const handlePick = (date: Date) => {
    if (isStart) {
      if (isRange && date > endDate) return;
      setStartDate(date);
      onPick && onPick({ startDate: date, endDate });
      if (isRange) setIsStart(false);
      return;
    }
    if (isRange && date < startDate) return;
    setEndDate(date);
    onPick && onPick({ startDate, endDate: date });
    setIsStart(true);
  };

  const newClass = () => {
    return classNames(styles.datepicker, className, { [styles.datepickerRange]: isRange });
  };

  const newInputClass = () => {
    return classNames(styles.datepickerInput, inputClassName);
  };

  return (
    <div className={newClass()} ref={calendarRef}>
      {labelLeft && <span className={styles.datepickerName}>{labelLeft}</span>}
      <Input
        name={labelTop ? labelTop : (labelLeft ? labelLeft : 'datepicker') + '-start'}
        placeholder={''}
        inputClassName={newInputClass()}
        labelClassName={styles.datepickerInputLabel}
        value={startDate.toLocaleDateString('nl-NL')}
        readOnly
        onClick={() => handleOpen(true)}
        leftLabel={labelTop ? labelTop + '-start' : ''}
      />
      {isRange && (
        <Input
          name={labelTop ? labelTop : (labelLeft ? labelLeft : 'datepicker') + '-end'}
          placeholder={''}
          inputClassName={newInputClass()}
          labelClassName={styles.datepickerInputLabel}
          value={endDate.toLocaleDateString('nl-NL')}
          readOnly
          onClick={() => handleOpen(false)}
          leftLabel={labelTop ? labelTop + '-end' : ''}
        />
      )}
      {show && (
        <Calendar
          calendarClassname={styles.datepickerCalendar}
          weekStart={'MONDAY'}
          onSelected={({ date }) => handlePick(date)}
          onClose={() => {
            setShow(false);
          }}
          closeOnClickOutside
          selectedStart={startDate}
          selectedEnd={isRange ? endDate : null}
        />
      )}
    </div>
  );
};

export default Datepicker;
