import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import styles from './style.module.scss';

interface IProps {
  className?: string;
  src: string;
  style?: React.CSSProperties;
}

const IFrame = ({ className, src, style }: IProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const frameRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    frameRef.current.addEventListener('load', () => {
      setIsLoading(false);
    });
  }, []);

  const newClassName = classNames(styles.iframe, { [styles.loading]: isLoading });

  const newStyles = {
    ...style,
  };

  return (
    <div className={styles.container} style={newStyles}>
      <iframe ref={frameRef} className={newClassName} src={src} frameBorder={'0'}>
        <p>Could not load iframe</p>
      </iframe>
      {isLoading && <p>Loading..</p>}
    </div>
  );
};

export default IFrame;
