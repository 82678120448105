import React, { useState } from 'react';
import AutoComplete from '~/components/AutoComplete';
import Button from '~/components/Button';
import Input from '~/components/Input';
import { Modal, ModalBody, ModalFooter, ModalTitle } from '~/components/Modal';
import { ToastVariants, useToast } from '~/components/Toast';
import { IDashboard } from '~/models/IDashboard';
import { IUser } from '~/models/IUser';
import { IWorkspace } from '~/models/IWorkspace';
import { addUserToDashboard } from '~/services/workspaces-service';

interface IProps {
  onClose: () => void;
  workspace: IWorkspace;
  dashboard: IDashboard;
  users: Array<IUser>;
}

const DashboardAddUser: React.FC<IProps> = ({ onClose, dashboard, users, workspace }: IProps) => {
  const [selected, setSelected] = useState<Array<string>>([]);
  const { addToast } = useToast();

  const handleSubmit = async () => {
    console.log('contact api');

    // console.log(workspace._id, dashboard._id);

    if (selected.length <= 0) {
      return;
    }

    console.log(selected);
    console.log(users);

    selected.forEach(async value => {
      const user: IUser = users.find(user => user.email === value);

      await addUserToDashboard(workspace._id, dashboard._id, user._id)
        .then(res => {
          if (res.success) {
            addToast('Added the user to the dashboard.');

            return onClose && onClose();
          }

          addToast('Failed to add the user.', ToastVariants.DANGER);
        })
        .catch(err => {
          console.error(err);
        });
    });
    // addUserToDashboard(workspace._id, dashboard._id,);
  };

  return (
    <Modal onClose={onClose}>
      <ModalTitle>Add user to {dashboard.type}</ModalTitle>
      <ModalBody>
        <Input style={{ width: '100%' }} readOnly={true} placeholder={dashboard.id} name="dashboard" />
        <Input style={{ width: '100%' }} readOnly={true} placeholder={dashboard.type} name="dashboard" />
        <AutoComplete
          suggestions={users.map(user => user.email)}
          selectCounter={true}
          onSelected={({ selected }) => setSelected(selected)}
        />
      </ModalBody>
      <ModalFooter style={{ justifyContent: 'flex-end' }}>
        <Button style={{ marginRight: '15px' }} onClick={onClose}>
          Close
        </Button>
        <Button onClick={handleSubmit}>Save changes</Button>
      </ModalFooter>
    </Modal>
  );
};

export default DashboardAddUser;
