import React, { useState } from 'react';
import classNames from 'classnames';
import styles from './styles.scss';

interface IProps {
  children: React.ReactNode;
  onEnter?: ({ canDrop }: { canDrop: boolean }) => void;
  onLeave?: () => void;
  onFileDrop?: (files: File[]) => void;
}

const DragAndDrop: React.FC<IProps> = ({ children, onLeave, onEnter, onFileDrop }: IProps) => {
  const [isDroppable, setDroppable] = useState<boolean>(false);

  const onDragOver = (event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const onDragEnter = (event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (event.dataTransfer === null) {
      onEnter && onEnter({ canDrop: false });
      return;
    }

    setDroppable(true);
    onEnter && onEnter({ canDrop: true });
  };

  const onDragLeave = (event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();

    setDroppable(false);
    onLeave && onLeave();
  };

  const onDrop = (event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (event.dataTransfer === null) {
      return;
    }

    const dataTransfer = event.dataTransfer.files;
    const files = [];

    for (let idx = 0; idx < dataTransfer.length; idx++) {
      const element = dataTransfer[idx];

      files.push(element);
    }

    setDroppable(false);
    onFileDrop && onFileDrop(files);
  };

  return (
    <div
      className={classNames(styles.wrapper, { [styles.wrapperDrop]: isDroppable })}
      onDragOver={onDragOver}
      onDragEnter={onDragEnter}
      onDrop={onDrop}
      onDragLeave={onDragLeave}
    >
      {!isDroppable && children}
      {isDroppable && (
        <div className={styles.wrapperDropContainer}>
          <div className={styles.wrapperDropContainerTitle}>Drop it like it&apos;s hot</div>
          <div className={classNames(styles.wrapperDropContainerTitle, styles.wrapperDropContainerTitleSub)}>
            Upload files by dropping them in this window.
          </div>
        </div>
      )}
    </div>
  );
};

export default DragAndDrop;
