import { Dispatch } from 'redux';

import { history } from '~/store';
import { handleVerification } from '~/services/auth-service';
import { Routes } from '~/routes/Routes';

import { VERIFY_REQUEST, VERIFY_REQUEST_SUCCESS, VERIFY_REQUEST_FAILURE } from '../constants';

export type Action = RequestAction | SuccessAction | FailureAction;

type RequestAction = { type: VERIFY_REQUEST; payload: boolean };
type SuccessAction = { type: VERIFY_REQUEST_SUCCESS; payload: boolean };
type FailureAction = { type: VERIFY_REQUEST_FAILURE; payload: boolean };

export const verify = (email: string, token: number) => {
  return (dispatch: Dispatch) => {
    const request = (): RequestAction => ({
      type: VERIFY_REQUEST,
      payload: true,
    });

    const success = (): SuccessAction => ({
      type: VERIFY_REQUEST_SUCCESS,
      payload: false,
    });

    const failure = (isFailure: boolean): FailureAction => ({
      type: VERIFY_REQUEST_FAILURE,
      payload: isFailure,
    });

    dispatch(request());

    handleVerification(email, token).then(
      () => {
        dispatch(success());
        setTimeout(() => {
          history.push(Routes.LOGIN);
        }, 3000);
      },
      () => {
        dispatch(failure(true));
      },
    );
  };
};
