import { Action } from '../actions/verify-actions';

import { VERIFY_REQUEST, VERIFY_REQUEST_SUCCESS, VERIFY_REQUEST_FAILURE } from '../constants';

export interface IVerifyState {
  isFailure: boolean;
  isLoading: boolean;
  isSuccess: boolean;
}

const initialState = {
  isFailure: false,
  isLoading: false,
  isSuccess: false,
};

export const verifyReducer = (state: IVerifyState = initialState, action: Action) => {
  switch (action.type) {
    case VERIFY_REQUEST: {
      return { ...state, isLoading: action.payload };
    }
    case VERIFY_REQUEST_SUCCESS: {
      return { ...state, isLoading: action.payload, isSuccess: true };
    }
    case VERIFY_REQUEST_FAILURE: {
      return { ...state, isLoading: false, isFailure: true };
    }
    default:
      return state;
  }
};
