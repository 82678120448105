import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { SuccessIcon, ErrorIcon, WarningIcon, InfoIcon } from '../../Icons';
import { useToast, ToastVariants } from '..';
import styles from '../styles.scss';

export interface IProps {
  id: number;
  children: React.ReactNode;
  variant?: ToastVariants;
  dismissTime?: number;
}

const Toast = ({ children, id, variant = ToastVariants.SUCCESS, dismissTime = 4500 }: IProps) => {
  const { removeToast } = useToast();
  const [isCompleted, setCompleted] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCompleted(true);
    }, dismissTime);

    return () => {
      clearTimeout(timer);
    };
  }, [id, removeToast]);

  const onComplete = () => {
    if (isCompleted) removeToast(id);
  };

  let icon = null;

  switch (variant) {
    case ToastVariants.SUCCESS:
      icon = SuccessIcon;
      break;
    case ToastVariants.DANGER:
      icon = ErrorIcon;
      break;
    case ToastVariants.WARNING:
      icon = WarningIcon;
      break;
    case ToastVariants.INFO:
      icon = InfoIcon;
      break;
  }

  return (
    <div
      key={id}
      onAnimationEnd={onComplete}
      className={classNames(styles.container, styles[variant], {
        [styles.containerCompleted]: isCompleted,
      })}
    >
      <button onClick={() => setCompleted(true)}>X</button>
      <div className={styles.containerImage}>
        <img src={icon} alt={`${variant}_icon`} />
      </div>
      <p className={styles.containerTitle}>{variant.charAt(0).toUpperCase() + variant.slice(1)}</p>
      <p className={styles.containerDescription}> {children}</p>
    </div>
  );
};

export default Toast;
