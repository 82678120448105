import Heading from './Heading';
import HeadingTitle from './HeadingTitle';
import HeadingBody from './HeadingBody';

export { Heading, HeadingTitle, HeadingBody };

export default {
  Heading,
  HeadingTitle,
  HeadingBody,
};
