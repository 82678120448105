import React, { useEffect, useState } from 'react';
import AppContent from '~/components/AppContent';
import styles from '../styles.scss';
import { Heading, HeadingBody, HeadingTitle } from '~/components/Heading';
import { IUser } from '~/models/IUser';
import { getUsers } from '~/services/users-service';
import { Table, Tbody, Td, Th, Thead, Tr } from '~/components/Table';
import { convertDate } from '~/utils/date';
import Pagination from '~/components/Pagination';
import Button from '~/components/Button';
import CreateUser from '../components/CreateUser';
import UserLookup from '../components/UserLookup';
import Input from '~/components/Input';
import Select from '~/components/Select';

const columns = [
  { label: '#', column: 'id' },
  { label: 'First Name', column: 'firstName' },
  { label: 'Last Name', column: 'lastName' },
  { label: 'Email', column: 'email' },
  { label: 'Role', column: 'role' },
  { label: 'Workspace(s)', column: 'workspaces' },
  { label: 'Verified', column: 'verified' },
  { label: 'Created', column: 'createdAt' },
];

const selectKeys = [
  { name: 'email', label: 'Email' },
  { name: 'firstName', label: 'First Name' },
  { name: 'lastName', label: 'Last Name' },
  { name: 'role', label: 'Role' },
];

const AdminUsersScreen: React.FC = () => {
  const [users, setUsers] = useState<Array<IUser> | undefined>(undefined);
  const [activeUser, setActiveUser] = useState<IUser | undefined>(undefined);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [maxPage, setMaxPage] = useState<number>(0);
  const [createUserModel, setCreateUserModel] = useState<boolean>(false);
  const [editUserModal, setEditUserModal] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchKey, setSearchKey] = useState<string>('email');
  const [search, setSearch] = useState<number>(0);
  const perPage = 25;

  useEffect(() => {
    setLoading(true);

    const loadUsers = async () => {
      await getUsers(perPage, currentPage, searchKey, searchValue)
        .then(response => {
          setMaxPage(response.pages.totalPages);
          setUsers(response.users);
          setLoading(false);
        })
        .catch(() => {
          setError(true);
          setLoading(false);
        });
    };

    const timeout = setTimeout(() => loadUsers(), 10);
    return () => clearTimeout(timeout);
  }, [currentPage, editUserModal, search, createUserModel]);

  const renderUsers = () => {
    if (isLoading) {
      return <span className={styles.notice}>Loading...</span>;
    }

    if (error) {
      return <span className={styles.notice}>Error occurred loading &apos;DEEP&apos; services.</span>;
    }

    if (users == undefined || users.length <= 0) {
      return <span className={styles.notice}>No user matched this query.</span>;
    }

    return (
      <Table>
        <Thead>
          <Tr>
            {columns.map((col, idx: number) => {
              return (
                <Th data-label={col} key={`col-${col.column}-${idx}`}>
                  {col.label}
                </Th>
              );
            })}
          </Tr>
        </Thead>
        <Tbody>
          {users &&
            users.map((user: IUser, idx) => {
              const max = currentPage * perPage;
              return (
                <Tr
                  onClick={() => {
                    setActiveUser(user);
                    setEditUserModal(!editUserModal);
                  }}
                  data-label={user.email}
                  key={`user-${user.email}-${idx}`}
                >
                  <Td>{max + idx + 1}</Td>
                  <Td>{user.firstName}</Td>
                  <Td>{user.lastName}</Td>
                  <Td>{user.email}</Td>
                  <Td>{user.role.toUpperCase()}</Td>
                  <Td>{user.workspaces.length}</Td>
                  <Td>{user.isVerified ? 'Yes' : ' No'}</Td>
                  <Td>{convertDate(user.createdAt)}</Td>
                </Tr>
              );
            })}
        </Tbody>
      </Table>
    );
  };

  return (
    <AppContent center={false}>
      {createUserModel && <CreateUser onClose={() => setCreateUserModel(!createUserModel)} />}
      {editUserModal && <UserLookup onClose={() => setEditUserModal(!editUserModal)} user={activeUser} />}
      <div className={styles.admin}>
        <Heading style={{ width: '450px' }}>
          <HeadingBody>
            <HeadingTitle>User Management</HeadingTitle>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum malesuada erat et pharetra accumsan.
            Phasellus semper nibh non ultricies placerat. Mauris in mi vel nibh viverra elementum ut at augue.
          </HeadingBody>
        </Heading>

        <div className={styles.adminBody}>
          <div className={styles.adminBodyActions}>
            <Button onClick={() => setCreateUserModel(!createUserModel)}>Create User</Button>
            <Select
              style={{ marginRight: '15px' }}
              options={selectKeys.map(key => key.label)}
              onChange={({ selected }) =>
                setSearchKey(selectKeys.find(key => key.label.toLowerCase() === selected.toLowerCase()).name)
              }
            />
            <Input
              onChange={({ value }) => {
                setSearchValue(value);
              }}
              onEnter={() => setSearch(value => value + 1)}
              placeholder="Search for value..."
              name="search"
            />
          </div>
          {renderUsers()}
        </div>
        <Pagination
          totalPages={maxPage}
          perPage={perPage}
          currentPage={currentPage}
          onChangePage={({ newPage }) => setCurrentPage(newPage)}
        />
      </div>
    </AppContent>
  );
};

export default AdminUsersScreen;
