import { AxiosError, AxiosResponse } from 'axios';
import { Auth } from '~/lib/auth-lib';
import axios from '~/lib/axios-client';
import { IFileshare } from '~/models/IFileshare';

export const getUserFiles = async (): Promise<IFileshare> => {
  return await axios
    .get(`/v1/fileshare`, {
      headers: {
        Authorization: `Bearer ${Auth.getToken()}`,
      },
    })
    .then((res: AxiosResponse) => {
      const data: IFileshare = res.data;

      if (data.success) {
        return data;
      }
    })
    .catch((err: AxiosError) => {
      if (err.response) {
        return err.response.data;
      }
    });
};

export const upload = async (files: Array<File>) => {
  const formData = new FormData();

  files.forEach(file => {
    formData.append('file', file);
  });

  const data = formData;

  const headers = {
    headers: {
      Authorization: `Bearer ${Auth.getToken()}`,
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: function (progressEvent: ProgressEvent) {
      const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      console.log(progress);
    },
  };

  return await axios.post(`/v1/fileshare/upload`, data, headers);
};

export const deleteFile = async (fileId: string) => {
  return await axios
    .delete(`/v1/fileshare/${fileId}`, {
      headers: {
        Authorization: `Bearer ${Auth.getToken()}`,
      },
    })
    .then((res: AxiosResponse) => {
      const data: any = res.data;

      if (data.success) {
        return data;
      }

      return data;
    })
    .catch((err: AxiosError) => {
      if (err.response) {
        return err.response;
      }
    });
};

export const downloadFile = async (fileId: string) => {
  return await axios
    .get(`/v1/fileshare/download/${fileId}`, {
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${Auth.getToken()}`,
      },
    })
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((err: AxiosError) => {
      if (err.response) {
        return err.response;
      }
    });
};
