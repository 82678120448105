import React from 'react';
import classNames from 'classnames';
import styles from './styles.scss';
import { SnackbarTypes } from '~/hooks/useSnackbar';

interface IProps {
  message: string;
  active: boolean;
  percentage?: number;
  color?: string;
  type?: SnackbarTypes;
}

const Snackbar: React.FC<IProps> = ({ message, active, percentage, type = 'default', color = '86c682' }: IProps) => {
  const snackClasses = classNames(styles.snackbar, {
    [styles.snackbarFadein]: active,
    [styles.snackbarFadeout]: !active,
    [styles[type]]: type,
  });

  return (
    <div className={snackClasses}>
      <span className={styles.snackbarText}>{message}</span>
      <div className={classNames(styles.snackbarFill)} style={{ width: `${76}%`, background: `#${color}` }}></div>
    </div>
  );
};

export default Snackbar;
