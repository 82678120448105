import ForgotPasswordScreen from './containers/ForgotPasswordScreen';
import LoginScreen from './containers/LoginScreen';
import LogoutScreen from './containers/LogoutScreen';
import RegisterScreen from './containers/RegisterScreen';
import VerifyScreen from './containers/VerifyScreen';

export { LoginScreen, LogoutScreen, ForgotPasswordScreen, VerifyScreen, RegisterScreen };

export default {
  LoginScreen,
  LogoutScreen,
  ForgotPasswordScreen,
  VerifyScreen,
  RegisterScreen,
};
