import { createStore, applyMiddleware } from 'redux';
import { createBrowserHistory } from 'history';
import thunkMiddleware from 'redux-thunk';
import { routerMiddleware as createRouterMiddleware, RouterState } from 'connected-react-router';

import { composeEnhancers } from './utils';
import rootReducer from './root-reducer';
import { INavigationState } from '../components/Navigation/reducers';
import { ILoginState } from '~/modules/Auth/reducers/login-reducers';
import { IRegisterState } from '~/modules/Auth/reducers/register-reducers';
import { IVerifyState } from '~/modules/Auth/reducers/verify-reducers';

export interface IRootState {
  router: RouterState;
  navigation: INavigationState;
  login: ILoginState;
  register: IRegisterState;
  verify: IVerifyState;
}

export const history = createBrowserHistory();

const routerMiddleware = createRouterMiddleware(history);

const middlewares = [thunkMiddleware, routerMiddleware];

const initialState = {};

const store = createStore(rootReducer(history), initialState, composeEnhancers(applyMiddleware(...middlewares)));

export default store;
