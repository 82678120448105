import { IRequestError } from '~/models/IRequest';
import { Action } from '../actions/login-actions';

import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE } from '../constants';

export interface IUser {
  email: string;
  firstName?: string;
  lastName?: string;
  isVerified?: boolean;
}

export interface ILoginState {
  isLoggedIn: boolean;
  isLoading: boolean;
  user: IUser;
  errors: Array<IRequestError> | undefined;
}

const initialState: ILoginState = {
  isLoggedIn: false,
  isLoading: false,
  user: { email: '' },
  errors: undefined,
};

export const loginReducer = (state: ILoginState = initialState, action: Action) => {
  switch (action.type) {
    case LOGIN_REQUEST: {
      return {
        ...state,
        isLoggedIn: true,
        user: action.payload,
        isLoading: true,
      };
    }
    case LOGIN_SUCCESS: {
      return {
        ...state,
        isLoggedIn: true,
        user: action.payload,
        isLoading: false,
      };
    }
    case LOGIN_FAILURE: {
      return {
        ...state,
        errors: action.payload,
        isLoggedIn: false,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};
