import React from 'react';
import AppContent from '~/components/AppContent';
import classNames from 'classnames';
import styles from '../styles.scss';
import { history } from '~/store';
import { Card, CardBody, CardFooter, CardHeader } from '~/components/Card';
import { Heading, HeadingBody, HeadingTitle } from '~/components/Heading';
import { Routes } from '~/routes/Routes';

const AdminScreen: React.FC = () => {
  return (
    <AppContent center={false}>
      <div className={classNames(styles.admin, styles.adminSmall)}>
        <Heading style={{ width: '450px' }}>
          <HeadingBody>
            <HeadingTitle>DEEP Admin-Panel</HeadingTitle>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum malesuada erat et pharetra accumsan.
            Phasellus semper nibh non ultricies placerat. Mauris in mi vel nibh viverra elementum ut at augue.
          </HeadingBody>
        </Heading>
        <div className={styles.adminBody}>
          <Card style={{ width: '350px' }} onClick={() => history.push(Routes.ADMIN_USER)}>
            <CardHeader>User Management</CardHeader>
            <CardBody>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
              et dolore magna.
            </CardBody>
            <CardFooter> MeMo²</CardFooter>
          </Card>
          <Card style={{ width: '350px' }} onClick={() => history.push(Routes.ADMIN_WORKSPACES)}>
            <CardHeader>Workspace Management</CardHeader>
            <CardBody>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
              et dolore magna.
            </CardBody>
            <CardFooter>MeMo²</CardFooter>
          </Card>
        </div>
      </div>
    </AppContent>
  );
};

export default AdminScreen;
