import React from 'react';
import classNames from 'classnames';
import styles from './styles.scss';

interface IProps {
  onClick?: (event: React.FormEvent<HTMLButtonElement>) => void;
  children?: React.ReactNode;
  isLoading?: boolean;
  uppercase?: boolean;
  fullWidth?: boolean;
  noMargin?: boolean;
  buttonType?: 'submit' | 'button';
  className?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  title?: string;
  type?: 'white' | 'gray' | 'yellow' | 'orange' | 'red' | 'green' | 'blue';
}

const Button = ({ onClick, type = 'green', buttonType = 'submit', style, disabled = false, ...props }: IProps) => {
  const handleClick = (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (disabled) return;

    onClick && onClick(event);
  };

  const buttonClasses = classNames(styles.button, props.className, {
    [styles[type]]: type,
    [styles.buttonUppercase]: props.uppercase,
    [styles.buttonFull]: props.fullWidth,
    [styles.buttonNoMargin]: props.noMargin,
  });

  const newStyles = {
    ...style,
  };

  return (
    <button style={newStyles} className={buttonClasses} onClick={handleClick} type={buttonType}>
      {props.children}
    </button>
  );
};

export default Button;
