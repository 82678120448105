import React, { useState } from 'react';
import AppContent from '~/components/AppContent';
import { Card, CardHeader, CardBody, CardFooter } from '~/components/Card';
import { useAsyncEffect } from '~/hooks/useAsyncEffect';
import { Auth } from '~/lib/auth-lib';
import { Routes } from '~/routes/Routes';
import { getUser } from '~/services/user-service';
import { history } from '~/store';
import { Heading, HeadingBody, HeadingTitle } from '../../../components/Heading';
import styles from '../styles.scss';

const AppScreen: React.FC = () => {
  const cards = [
    { name: 'dashboards', label: 'Dashboards', route: Routes.DASHBOARDING },
    { name: 'reports', label: 'Reports', route: Routes.REPORTS },
    { name: 'fileShare', label: 'File Share', route: Routes.FILESHARE },
    { name: 'contact', label: 'Contact Us' },
  ];

  const [test, setTest] = useState<boolean>(false);

  useAsyncEffect(
    async () => {
      console.log('mount');
      await getUser().then(res => console.log(res.user));
      console.log('foo mount');
    },
    () => {
      console.log('unmount');
    },
    [test],
  );

  // useAsyncEffect(async () => {
  //   console.log(test);
  //   console.log('mount');
  //   await getUser().then(res => console.log(res.user));
  //   console.log('foo mount');
  // }),
  //   [test];

  return (
    <AppContent center={false}>
      <div className={styles.column}>
        <Heading>
          <HeadingBody>
            <HeadingTitle>Hello, {Auth.getUser().firstName + ' ' + Auth.getUser().lastName}.</HeadingTitle>
          </HeadingBody>
        </Heading>
        <button onClick={() => setTest(!test)}>foo</button>
        <div className={styles.cards}>
          {cards.map(({ label, name, route }) => (
            <Card key={name} className={styles.cardsCard} onClick={() => history.push(route)}>
              <CardHeader>{label}</CardHeader>
              <CardBody>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
                labore et dolore magna.
              </CardBody>
              <CardFooter>MeMo²</CardFooter>
            </Card>
          ))}
        </div>
      </div>
    </AppContent>
  );
};

export default AppScreen;
