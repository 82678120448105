import React, { useState } from 'react';
import AppContent from '~/components/AppContent';
import Button from '~/components/Button';
import Input from '~/components/Input';
import { useToast } from '~/components/Toast';
import { Auth } from '~/lib/auth-lib';
import { updateUser } from '~/services/user-service';
import styles from '../styles.scss';
import { Modal, ModalBody, ModalFooter, ModalTitle } from '~/components/Modal';

const UserSettingsScreen: React.FC = () => {
  const { addToast } = useToast();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [newFirstName, setNewFirstName] = useState<string | undefined>(undefined);
  const [newLastName, setNewLastName] = useState<string | undefined>(undefined);
  const [newPassword, setNewPassword] = useState<string | undefined>(undefined);
  const [modal, setModal] = useState<boolean>(false);

  const handleSubmit = (): void => {
    console.log(newFirstName, newPassword);

    if (isLoading) {
      return;
    }

    setModal(false);
    setLoading(true);

    updateUser(newPassword, newFirstName, newLastName).then(data => {
      setTimeout(() => {
        setLoading(false);
        if (data.success) {
          addToast('Succesfully updated your account');
        }
      }, 1500);
    });
  };

  return (
    <AppContent>
      {modal && (
        <Modal>
          <ModalTitle>Change Password</ModalTitle>
          <ModalBody>
            <Input
              onChange={({ value }) => console.log(value)}
              type="password"
              groupClassName={styles.group}
              tabIndex={2}
              placeholder="••••••••"
              leftLabel="Old Password"
              name="oldPassword"
            />
            <Input
              onChange={({ value }) => setNewPassword(value)}
              type="password"
              groupClassName={styles.group}
              tabIndex={2}
              placeholder="••••••••"
              leftLabel="New Password"
              name="newPassword"
            />
            <Input
              onChange={({ value }) => console.log(value)}
              type="password"
              groupClassName={styles.group}
              tabIndex={2}
              placeholder="••••••••"
              leftLabel="Confirm New Password"
              name="confirmNewPassword"
            />
          </ModalBody>
          <ModalFooter style={{ justifyContent: 'flex-end' }}>
            <Button style={{ marginRight: '15px' }} onClick={() => setModal(!modal)}>
              Close
            </Button>
            <Button onClick={handleSubmit}>Update Password</Button>
          </ModalFooter>
        </Modal>
      )}
      <div className={styles.settings}>
        <div className={styles.settingsTitle}>Account Settings</div>
        <div className={styles.settingsIcon}>
          <div className={styles.settingsIconInitials}>{Auth.getUserInitials()}</div>
        </div>
        <Input
          onChange={({ value }) => setNewFirstName(value)}
          type="text"
          groupClassName={styles.group}
          tabIndex={1}
          placeholder={Auth.getUser().firstName}
          leftLabel="First Name"
          name="firstName"
        />
        <Input
          onChange={({ value }) => setNewLastName(value)}
          type="text"
          groupClassName={styles.group}
          tabIndex={1}
          placeholder={Auth.getUser().lastName}
          leftLabel="Last Name"
          name="lastName"
        />
        <Input
          onChange={({ value }) => console.log(value)}
          type="text"
          tabIndex={4}
          groupClassName={styles.group}
          placeholder={'+31687654321'}
          leftLabel="Phone Number"
          name="phoneNumber"
        />
        <Input
          onChange={({ value }) => console.log(value)}
          type="text"
          tabIndex={5}
          groupClassName={styles.group}
          placeholder={'CEO'}
          leftLabel="Function"
          name="function"
        />
        <Button className={styles.button} onClick={handleSubmit} isLoading={isLoading}>
          Update Settings
        </Button>
        <Button type="orange" style={{ marginTop: '0' }} className={styles.button} onClick={() => setModal(!modal)}>
          Change Password
        </Button>
      </div>
    </AppContent>
  );
};

export default UserSettingsScreen;
